import React, { useState, useEffect } from 'react';
import CementForm from '../Forms/CementForm'; // Import your CementForm component
import SteelForm from '../Forms/SteelForm'; // Import your SteelForm component
import NewCodeForm from '../Forms/NewCodeForm'; // Import your NewCodeForm component
import CodeUnblock from '../Forms/Code_unblock';
import Bank from '../Components/bankdetail';
import './RawMat.css';
import firebase from '../Firebase/firebase';

function RawMaterials() {
  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
    });
  };


  useEffect(() => {
    scrollToTop();
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts



  const [selectedForm, setSelectedForm] = useState('cement');
  const [showDownloadOptions, setShowDownloadOptions] = useState(false);
  const [userUid, setUserUid] = useState(null); // Store the user's UID

  useEffect(() => {
    const user = firebase.auth().currentUser;
    if (user) {
      setUserUid(user.uid); // Set the user's UID
    }
  }, []);

  const handleFormChange = (form) => {
    setSelectedForm(form);
  };

  const handleDownloadsHover = () => {
    setShowDownloadOptions(true);
  };

  const handleDownloadsLeave = () => {
    setShowDownloadOptions(false);
  };

  const handleDownloadOption = (option) => {
    const isConfirmed = window.confirm(`Do you want to download the file "${option.name}"?`);
    if (isConfirmed) {
      downloadFile(userUid, option);
    }
  };

  const downloadOptions = [
    { name: 'Last statement', folderName: 'Statement', fileName: 'statement.pdf' },
    { name: 'MTC Certificate', folderName: 'MTC', fileName: 'MTC.pdf' },
    { name: 'Others', folderName: 'Others', fileName: 'Other.pdf' },
  ];

  const downloadFile = async (userUid, option) => {
    try {
      const folderName = `${option.folderName}/${userUid}`;
      const fileName = option.fileName;
      const storageRef = firebase.storage().ref(folderName).child(fileName);
      const url = await storageRef.getDownloadURL();
      
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank'; // Open the link in a new tab or window
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };
  
  return (
    <div className="raw-materials">
      <h1>Raw Materials</h1>
      <div className="navbar">
        <ul>
          <li>
            <span  onClick={() => handleFormChange('cement')}>Cement Order</span>
          </li>
          <li>
            <span onClick={() => handleFormChange('steel')}>Steel Order</span>
          </li>
          <li>
            <span
              onMouseEnter={handleDownloadsHover}
              onMouseLeave={handleDownloadsLeave}
            >
              Downloads
              {showDownloadOptions && (
                <ul className="download-options">
                  {downloadOptions.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => handleDownloadOption(option)}
                    >
                      {option.name}
                    </li>
                  ))}
                </ul>
              )}
            </span>
          </li>
          <li>
            <span onClick={() => handleFormChange('newcode')}>New Code</span>
          </li>
          <li>
          <span onClick={() => handleFormChange('bankdetails')}>Bank Details</span>
          </li>
          <li>
          <span onClick={() => handleFormChange('codeunblock')}>Code Unblock</span>
          </li>
        </ul>
      </div>
      <div className="content-box">
  {selectedForm === 'cement' ? (
    <div className="form-box">
      <CementForm />
    </div>
  ) : null}

  {selectedForm === 'steel' ? (
    <div className="form-box">
      <SteelForm />
    </div>
  ) : null}

  {selectedForm === 'newcode' ? (
    <div className="form-box">
      <NewCodeForm />
    </div>
  ) : null}

{selectedForm === 'codeunblock' ? (
    <div className="form-box">
      <CodeUnblock />
    </div>
  ) : null}

{selectedForm === 'bankdetails' ? (
    <div className="form-box">
      <Bank />
    </div>
  ) : null}

</div>

    </div>
  );
}

export default RawMaterials;