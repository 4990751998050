import React, { useState } from 'react';
import firebase from '../Firebase/firebase'; // Import your Firebase configuration
import './EnquiryForm.css'; // Import the new CSS file

function EnquiryForm() {
  const [enquiryData, setEnquiryData] = useState({
    Duration: '',
    Loan_Amount: '',
    Message: '',
    Project: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnquiryData({
      ...enquiryData,
      [name]: value,
    });
  };

  const clearForm = () => {
    setEnquiryData({
      Duration: '',
      Loan_Amount: '',
      Message: '',
      Project: '',
    });
  };

  const getCurrentTimestamp = () => {
    return new Date().toLocaleString(); // Get the current timestamp in a human-readable format
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    if (enquiryData.Duration && enquiryData.Loan_Amount && enquiryData.Project && enquiryData.Message) {
      // Check if the user is authenticated
      const user = firebase.auth().currentUser;
      if (user) {
        const userUID = user.uid; // Get the user's UID

        // Add the current timestamp
        enquiryData.timestamp = getCurrentTimestamp();

        // Get a reference to the Firebase database
        const database = firebase.database();

        // Create a new node and push the enquiry data to it under the "Plus View" node with the user's UID
        const profileRef = database.ref(`Enquiry Loan/${userUID}`);
        profileRef.push(enquiryData, (error) => {
          if (!error) {
            setFormSubmitted(true);
            setTimeout(() => {
              setFormSubmitted(false);
            }, 2000); // Reset the message after 1 second
          }
        });

        // Clear the form after submission
        clearForm();
      } else {
        console.log('User is not authenticated.');
      }
    }
  };

  return (
    <div className="enquiry-container">
      <h1 className="heading">Any Questions, Ask Us?</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Type of Loan:</label>
          <select
            name="Duration"
            value={enquiryData.Duration}
            onChange={handleChange}
            required
          >
            <option value="Term loan">Term loan</option>
            <option value="Strat-up loan">Strat-up loan</option>
            <option value="Working Capital loan">Working Capital loan</option>
            <option value="Loan against property for SME">Loan against property for SME</option>
            <option value="Invoice Financeing">Invoice Financeing</option>
          </select>
        </div>
        <div className="form-group">
          <label>Loan Amount:</label>
          <input
            type="text"
            name="Loan_Amount"
            value={enquiryData.Loan_Amount}
            onChange={handleChange}
            placeholder="amount"
            required
          />
        </div>
        <div className="form-group">
          <label>Project:</label>
          <input
            type="text"
            name="Project"
            value={enquiryData.Project}
            onChange={handleChange}
            placeholder="project name"
            required
          />
        </div>
        <div className="form-group">
          <label>Message:</label>
          <input
            type="text"
            name="Message"
            value={enquiryData.Message}
            onChange={handleChange}
            placeholder="your enquiry"
            required
          />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
        {formSubmitted && <p>Form submitted successfully!</p>}
      </form>
    </div>
  );
}

export default EnquiryForm;
