import React from 'react';
import './vendor.css'; // Import your CSS file
import { useNavigate } from 'react-router-dom'; 

const VendorPage = () => {

  const navigate = useNavigate(); 

  return (
    <div className="vendor-page">
      <header className="vendor_header">
        <div className="text-container">
          <h1>Become a Vendor with Us</h1>
          <h2>Sell your products with Altrange.com</h2>
          <button  onClick={() => navigate('/vendorsform')}>
  Start Here
</button>
        </div>
        <img src="/Images/vendorhome.png" alt="Header" className="header-image" />
      </header>
      <div className="vendor-steps-container">
      <h2>Get Started in Four Easy Steps</h2>
      <div className="steps">
        <div className="step">
          <img src="/Images/register.png" alt="Step 1" />
          <p>Step 1: Register your company with us.</p>
        </div>
        <div className="step">
          <img src="/Images/details.png" alt="Step 2" />
          <p>Step 2: Fill the Product informaiton form.</p>
        </div>
        <div className="step">
          <img src="/Images/contact.png" alt="Step 3" />
          <p>Step 3: We will contact you soon for confirmation.</p>
        </div>
        <div className="step">
          <img src="/Images/listed.png" alt="Step 4" />
          <p>Step 4: Your product would be listed soon within 4-5 working days.</p>
        </div>
      </div>
    </div>
    <div className="benefits-container">
        <h2>Benefits of Listing Products with Us</h2>
        <ul className="benefits-list">
          <li>&bull; <strong>Sales Growth:</strong> Drive sales growth through the additional channel provided by your online marketplace.</li>
          <li>&bull; <strong>Market Expansion:</strong> Explore new markets and expand your customer base beyond traditional boundaries.</li>
          <li>&bull; <strong>Customer Trust:</strong> Gain trust from customers who prefer shopping on established and recognized platforms.</li>
          <li>&bull; <strong>Increased Visibility:</strong> Reach a broader audience and increase the visibility of your products to potential customers.</li>
          <li>&bull; <strong>Brand Exposure:</strong> Boost brand exposure by showcasing your products on a reputable and widely-visited online platform.</li>
        </ul>
      </div>
      <div>
    </div>
    </div>
    
  );
};

export default VendorPage;
