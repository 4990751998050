import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './newsite.css'; // Import your CSS file

const AddNewSiteAddress = () => {
  const [newSiteAddress, setNewSiteAddress] = useState('');
  const [cementGrade, setCementGrade] = useState('');
  const [quantity, setQuantity] = useState('');
  const [siteAddresses, setSiteAddresses] = useState([]); 
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showInputField, setShowInputField] = useState(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        fetchSiteAddresses(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchSiteAddresses = async (userId) => {
    try {
      const siteAddressRef = firebase.database().ref(`SiteAddress/${userId}`);
      const snapshot = await siteAddressRef.once('value');

      if (snapshot.exists()) {
        const data = snapshot.val();
        const addresses = Object.values(data).map(site => site.address);
        setSiteAddresses(addresses);
      } else {
        await siteAddressRef.set({ exampleNode: true });
        setSiteAddresses([]);
      }
    } catch (error) {
      console.error('Error fetching site addresses:', error);
    }
  };

  const handleAddSiteAddress = async () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser && newSiteAddress.trim() && cementGrade.trim() && quantity.trim()) {
      const quantityValue = parseInt(quantity, 10);
      if (quantityValue >= 2000) {
        try {
          const userId = currentUser.uid;
          const siteAddressRef = firebase.database().ref(`SiteAddress/${userId}`);
          const existingSiteSnapshot = await siteAddressRef.once('value');
          const existingSiteAddresses = existingSiteSnapshot.val();

          // Check if the grade already exists for the given address
          const gradeExists = Object.values(existingSiteAddresses || {}).some(
            site => site.address === newSiteAddress && site.cementGrade === cementGrade
          );

          if (gradeExists) {
            setErrorMessage('This grade already exists for the selected site address. Please use a different grade.');
          } else {
            const nextIndex = existingSiteAddresses ? Object.keys(existingSiteAddresses).length + 1 : 1;
            const newNodeName = `siteAddress${nextIndex}`;

            await siteAddressRef.child(newNodeName).set({
              address: newSiteAddress,
              cementGrade,
              quantity
            });

            // Handle adding site rates
            addSiteRates(userId, newNodeName, newSiteAddress);

            // Clear the form fields
            setNewSiteAddress('');
            setCementGrade('');
            setQuantity('');
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 10000);
          }
        } catch (error) {
          console.error('Error adding new site address:', error);
          setErrorMessage('Failed to add site address. Please try again.');
        }
      } else {
        setErrorMessage('Minimum quantity should be 2000 bags');
      }
    } else {
      setErrorMessage('Please fill all fields');
    }
  };

  const addSiteRates = async (userId, siteNodeName, siteAddress) => {
    const siteRateRef = firebase.database().ref(`SiteRate/${userId}`);
    const brands = ["Acc", "Jksuper", "JSW", "Shree", "Ultratech"];

    for (const brand of brands) {
      const brandRef = siteRateRef.child(brand).child(siteNodeName);

      // Fetch and set rates for each rate type
      await getRateForAddress(userId, siteAddress, brand, 'rate', rate => {
        brandRef.child('rate').set(rate);
      });
    }
  };

  const getRateForAddress = async (userId, siteAddress, brand, rateType, callback) => {
    try {
      const rateRef = firebase.database().ref(`Rates/${userId}/${siteAddress}/${brand}/${rateType}`);
      const snapshot = await rateRef.once('value');
      const rate = snapshot.val() || 'to be updated'; // Default message if null
      callback(rate);
    } catch (error) {
      console.error('Error fetching rate:', error);
      callback('to be updated'); // Default message on error
    }
  };

  const handleInfoIconClick = () => {
    alert('Add new construction sites for cement rates with address, cement grade, and quantity details.');
  };

  return (
    <div className="address-container">
      <h1>Check Rates for your Construction Site</h1>
      
      <div className={`site-container ${showSuccessMessage ? 'hidden' : ''}`}>
        <div className="site-frame">
          <div className='newsite-data'>
            <span>
              Add New Site here  
              <img 
                src={`${process.env.PUBLIC_URL}/Images/info-icon.png`} 
                className='address-info-icon' 
                onClick={handleInfoIconClick} 
                alt="info-icon"
              />
            </span>
          </div>
          <input
            id="newSiteAddress"
            type="text"
            placeholder="Site Address"
            value={newSiteAddress}
            onChange={(e) => setNewSiteAddress(e.target.value)}
            required
          />
          <input
            id="cementGrade"
            type="text"
            placeholder="Grade"
            value={cementGrade}
            onChange={(e) => setCementGrade(e.target.value)}
            required
          />
          <input
            id="quantity"
            type="number"
            min="2000"
            placeholder="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            required
          />
          <button onClick={handleAddSiteAddress}>Generate Site</button>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
  
      {showSuccessMessage && (
        <div className="success-message">
          <p>Got your Site! We are working on getting competitive prices for your site. Rates would be updated soon. Please check in your "Existing Sites" option in some time.</p>
        </div>
      )}
    </div>
  );
};

export default AddNewSiteAddress;
