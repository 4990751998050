import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase'; // Import your Firebase configuration
import './cement_form.css';
import { Timestamp } from 'firebase/firestore';

function CementOrderForm() {

  const [cementOrder, setCementOrder] = useState({
    company: '',
    grade: '',
    order: '',
    address: '',
    payment: '', // Payment field included
    poImage: null, // Upload document fields included
    paymentImage: null, // Upload document fields included
    Timestamp: '',
  });

  const cementBrands = [
    {
      name: 'Ultratech',
      imageName: 'ultratechbag.png',
    },
    {
      name: 'Shree',
      imageName: 'shree-img.webp',
    },
    {
      name: 'JK Super',
      imageName: 'jkcement.png',
    },
    {
      name: 'JSW',
      imageName: 'jswbag.png',
    },
    {
      name: 'ACC',
      imageName: 'accbag.png',
    },
  ];

  const cementGradeOptions = [
    {
      name: 'PPC',
      imageName: 'PPC.png',
    },
    {
      name: 'OPC 43',
      imageName: 'OPC-43.png',
    },
    {
      name: 'OPC 53',
      imageName: 'OPC-53.png',
    },
    {
      name: 'PSC',
      imageName: 'PSC.png',
    },
  ];

  const [siteAddresses, setSiteAddresses] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [sessionSubmissions, setSessionSubmissions] = useState(0);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [showCustomAddressInput, setShowCustomAddressInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  

  const handleBrandSelection = (selectedBrand) => {
    setCementOrder({ ...cementOrder, company: selectedBrand });
  };

  const handleGradeSelection = (selectedGrade) => {
    setCementOrder({ ...cementOrder, grade: selectedGrade });
  };

  const handleQuantitySelection = (selectedQuantity) => {
    if (selectedQuantity === 'custom') {
      setShowCustomInput(true);
      setCementOrder({ ...cementOrder, order: '' });
    } else {
      setShowCustomInput(false);
      setCementOrder({ ...cementOrder, order: selectedQuantity });
    }
  };

  const handleOtherSelection = () => {
    setShowCustomInput(true);
    setCementOrder({ ...cementOrder, order: 'other' });
  };

  const handleCustomQuantityChange = (e) => {
    let value = e.target.value;
    const maxLength = 5; // Maximum number of digits allowed

    // Truncate the value if it exceeds the maximum length
    if (value.length > maxLength) {
      value = value.slice(0, maxLength);
    }
    // Update the order value directly
    setCementOrder({ ...cementOrder, order: value });
  };

  const handleAddressSelection = (selectedAddress) => {
    // If the selected address is 'custom', show the custom address input field
    if (selectedAddress === 'custom') {
      setShowCustomAddressInput(true);
    } else {
      // Otherwise, hide the custom address input field and update the selected address
      setShowCustomAddressInput(false);
      setCementOrder({ ...cementOrder, address: selectedAddress });
    }
  };

  // Function to handle the change in custom address input
  const handleCustomAddressChange = (e) => {
    // Update the address value directly
    setCementOrder({ ...cementOrder, address: e.target.value });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCementOrder(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    const user = firebase.auth().currentUser;
    const userUID = user.uid;

    const fetchSiteAddresses = async () => {
      try {
        const snapshot = await firebase.database().ref(`SiteAddress/${userUID}`).once('value');
        if (snapshot.exists()) {
          const addresses = snapshot.val();
          const addressesArray = Object.keys(addresses).map(key => ({ id: key, address: addresses[key].address }));
          setSiteAddresses(addressesArray);
        }
      } catch (error) {
        console.error('Error fetching site addresses:', error);
      }
    };

    fetchSiteAddresses();
  }, []);

  useEffect(() => {
    const user = firebase.auth().currentUser;
    const userUID = user.uid;

    const currentDate = new Date().toISOString().slice(0, 10);

    const fetchUserOrders = async () => {
      try {
        const snapshot = await firebase.database().ref(`Plus View/${userUID}`).orderByChild('timestamp').startAt(`${currentDate} 00:00:00`).endAt(`${currentDate} 23:59:59`).once('value');
        if (snapshot.exists()) {
          const orders = snapshot.val();
          const orderCount = Object.keys(orders).length;
          setSessionSubmissions(orderCount);
        }
      } catch (error) {
        console.error('Error fetching user orders:', error);
      }
    };

    fetchUserOrders();
  }, []);

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file) {
      if (file.size > maxSize) {
        console.error('File size exceeds the limit of 5MB.');
        alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
        e.target.value = null;
        return;
      }

      const storageRef = firebase.storage().ref();
      const user = firebase.auth().currentUser;

      if (user) {
        const userUID = user.uid;
        const fileRef = storageRef.child(`PO/${userUID}/${fileType}_${file.name}`);

        // Delete the previous file
        const prevFileRef = storageRef.child(`PO/${userUID}/${cementOrder[fileType + 'Image']}`);
        prevFileRef.delete().catch(error => {
          console.error('Error deleting previous file:', error);
        });

        const uploadTask = fileRef.put(file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            console.error('Error uploading file:', error);
            alert('Error uploading file. Please try again.');
          },
          () => {
            console.log('File uploaded successfully');
            // Update local state with uploaded file URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              setCementOrder((prevState) => ({
                ...prevState,
                [fileType + 'Image']: downloadURL, // Update only the file upload details in the local state
              }));
            });
          }
        );
      }
    }
  };

  const clearForm = () => {
    setCementOrder({
      company: '',
      grade: '',
      order: '',
      address: '',
      payment: '',
      poImage: null,
      paymentImage: null,
    });
  };

  const getCurrentTimestamp = () => {
    return new Date().toLocaleString();
  };

  const sendSmsUsingAmazeSMSAPI = async () => {
    const currentUser = firebase.auth().currentUser;

    if (currentUser) {
      const database = firebase.database();
      const userUID = currentUser.uid;

      try {
        const snapshot = await database.ref(`profile/${userUID}/phone`).once('value');
        const phoneNumber = snapshot.val();

        if (phoneNumber) {
          const apiUrl = 'http://amazesms.in/api/pushsms';
          const user = 'gautam02';
          const authKey = '92H0u1bc1mipc';
          const sender = 'ALTNGE';
          let messageText = 'Dear user, your order has been placed successfully. Thank you for placing the order with us. Click bit.ly/Altrange - Altrange';
          const entityId = '1401584720000050327';
          const templateId = '1407168931664051688';

          messageText = encodeURIComponent(messageText);

          const fullUrl = `${apiUrl}?user=${user}&authkey=${authKey}&sender=${sender}&mobile=${phoneNumber}&text=${messageText}&entityid=${entityId}&templateid=${templateId}`;

          const response = await fetch(fullUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (response.ok) {
            console.log('SMS sent successfully!');
          } else {
            console.error('Failed to send SMS');
          }
        } else {
          console.error('Phone number not found in the database');
        }
      } catch (error) {
        console.error('Error fetching the phone number:', error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any required fields are empty
    const requiredFields = ['company', 'grade', 'order', 'address'];
    const emptyFields = requiredFields.filter(field => !cementOrder[field]);

    if (emptyFields.length > 0) {
      const fieldNames = emptyFields.map(field => {
        // Convert field name to a more user-friendly format
        return field.charAt(0).toUpperCase() + field.slice(1);
      });

      const errorMessage = `Please fill in the following required fields: ${fieldNames.join(', ')}.`;
      setErrorMessage(errorMessage);
      return;
    }

    try {
      const currentDate = getCurrentTimestamp();

      // Create a new object with only the necessary fields
      const orderData = {
        company: cementOrder.company,
        grade: cementOrder.grade,
        order: cementOrder.order,
        address: cementOrder.address,
        payment: cementOrder.payment,
        "Date & Time": currentDate,
      };

      const user = firebase.auth().currentUser;
      if (user) {
        const userUID = user.uid;
        const database = firebase.database();
        const profileRef = database.ref(`Plus View/${userUID}`);

        // Push order data to database
        profileRef.push(orderData);

        setFormSubmitted(true);
        setSuccessMessage('Form submitted successfully!');
        setTimeout(() => {
          setSuccessMessage('');
          setFormSubmitted(false);
        }, 2000);

        clearForm();
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('Error submitting form. Please try again.');
    }
  };

  const handlesitealert =()=>{
    alert("Site Info: Your order will be delivered to the address provided in a timely manner.")
  }
  const handlegradealert =()=>{
    alert("Grade Info: (MOQ) Minimum Order Quantity. All MOQ has been pre-decided by the company area-wise. Kindly confirm your MOQ order area-wise before placing the order.")
  }

  const handlecementalert =()=>{
    alert("Cement Info: \nUltratech Cement :  Ultratech Cement is known for its superior quality and durability. It is widely used in construction projects for its high strength and long-lasting performance.\n\nShree Cement :  Shree Cement is renowned for its high performance and environmental sustainability. It is a preferred choice for both residential and commercial construction projects.\n\nJK Super Cement :  JK Super Cement offers excellent strength and durability, making it ideal for a wide range of construction applications. Its advanced formulation ensures superior performance and long-lasting quality.\n\nJSW Cement :  JSW Cement provides high compressive strength and superior durability, suitable for both residential and industrial projects. Its eco-friendly manufacturing process ensures minimal environmental impact.\n\nAcc Cement :  ACC Cement is known for its high-quality standards and consistent performance. It is widely used in various construction projects for its excellent strength and durability.")
  }
  return (
    <div className="cement-form">
      <h1 className="heading">Cement Order</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group-container">
          <div className="form-group">
          
            <div className='balance-data' ><label>Select Cement Brand<img src={`${process.env.PUBLIC_URL}/Images/info-icon.png`} onClick={handlecementalert} className='info-img' /></label></div>
            
            <div className="cement-brands-container">
              {cementBrands.map((brand, index) => (
                <div
                  key={index}
                  className={`cement-brand-container ${cementOrder.company === brand.name ? 'selected' : ''}`}
                  onClick={() => handleBrandSelection(brand.name)}
                >
                  <img src={`/Images/${brand.imageName}`} alt={brand.name} />
                  <label className="cement-brand-label">{brand.name}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="form-group">
            <label>Select Cement Grade</label>
            <div className="cement-grades-container">
              {cementGradeOptions.map((grade, index) => (
                <div
                  key={index}
                  className={`cement-grade-container ${cementOrder.grade === grade.name ? 'selected' : ''}`}
                  onClick={() => handleGradeSelection(grade.name)}
                >
                  <img src={`/Images/${grade.imageName}`} alt={grade.name} />
                  <label className="cement-grade-label">{grade.name}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="form-group">
            
              <div className='balance-data' ><label>Site Address  <img src={`${process.env.PUBLIC_URL}/Images/info-icon.png`} onClick={handlesitealert} className='info-img' /></label></div>
           
            <div className="site-address-options">
              <label className="site-address-label">
                <input
                  type="radio"
                  name="address"
                  value="custom"
                  checked={cementOrder.address === "custom"}
                  onChange={() => handleAddressSelection("custom")}
                />
                <span className="address-text">Enter New Address</span>
              </label>
              {showCustomAddressInput && (
                <input
                  type="text"
                  name="address"
                  value={cementOrder.address || ''}
                  onChange={handleCustomAddressChange}
                  placeholder="Enter your site address"
                />
              )}

              {/* Render fetched addresses */}
              {siteAddresses.map(site => (
                <label key={site.id} className="site-address-label">
                  <input
                    type="radio"
                    name="address"
                    value={site.address}
                    checked={cementOrder.address === site.address}
                    onChange={() => handleAddressSelection(site.address)}
                  />
                  <span className="address-text">{site.address}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="form-group">
           
              <div className='balance-data' ><label>Quantity (Bags) <img src={`${process.env.PUBLIC_URL}/Images/info-icon.png`} onClick={handlegradealert} className='info-img' /></label></div>
            
            <div className="quantity-matrix">
              {[240, 350, 480, 500, 720, 800, 1000, 1200, 2000].map((quantity, index) => (
                <div
                  key={index}
                  className={`quantity-button ${cementOrder.order === quantity ? 'selected' : ''}`}
                  onClick={() => handleQuantitySelection(quantity)}
                >
                  {quantity}
                </div>
              ))}
              <div
                className={`quantity-button ${cementOrder.order === 'other' && showCustomInput ? 'selected' : ''}`}
                onClick={handleOtherSelection}
              >
                Other
              </div>
            </div>
            <div className="custom-quantity-container">
              {showCustomInput && (
                <input
                  type="number"
                  name="order"
                  value={cementOrder.order || ''}
                  onChange={handleCustomQuantityChange}
                  placeholder="Enter Qty"
                />
              )}
            </div>
          </div>

          <div className="form-group">
            <label>Payment (&#8377;)</label>
            <input
              type="text"
              name="payment"
              placeholder="Amount"
              value={cementOrder.payment}
              onChange={handleChange}
            />

            <label>Upload P.O</label>
            <input
              type="file"
              name="poImage"
              onChange={(e) => handleFileChange(e, 'PO')}
            />

            <label>Upload Payment Details</label>
            <input
              type="file"
              name="paymentImage"
              onChange={(e) => handleFileChange(e, 'PaymentDetails')}
            />
          </div>
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>

        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </div>
  );
}

export default CementOrderForm;
