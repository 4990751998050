import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './steel_form.css'; // Import your CSS file

function SteelOrderForm() {
  const [steelOrder, setSteelOrder] = useState({
    company: '',
    grade: '',
    order: '',
    address: '',
    payment: '',
    timestamp:'',
  });

  const [successMessage, setSuccessMessage] = useState(''); // State for success message

// Use unique identifiers for each form
const formId = 'form1'; // Unique ID for Form 1 (change for other forms)
// ...

// Fetch the submission count for the specific form
const [successfulSubmissions, setSuccessfulSubmissions] = useState(
  parseInt(localStorage.getItem(`submissionCount_${formId}`)) || 0
);

  useEffect(() => {
    const storedDate = localStorage.getItem('date');
    const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format
  
    if (storedDate !== currentDate) {
      localStorage.setItem('date', currentDate);
      localStorage.setItem('submissionCount', '0');
      setSuccessfulSubmissions(0);
    }
  }, []);
  

  useEffect(() => {
    // Add an event listener to detect when the user logs in
    const auth = firebase.auth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const userUID = user.uid;
        // Store userUID in a state variable
        setUserUID(userUID);
      }
    });

    // Clean up the event listener
    return () => {
      unsubscribe();
    };
  }, []);

  const [userUID, setUserUID] = useState(null); // State for user UID

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSteelOrder({ ...steelOrder, [name]: value });
  };

 const handleSubmit = (e) => {
  e.preventDefault();

  if (successfulSubmissions >= 5) {
    setSuccessMessage('Your order quota for today is finished');
    return;
  }

  const updatedCount = successfulSubmissions + 1;
  setSuccessfulSubmissions(updatedCount);
  localStorage.setItem(`submissionCount_${formId}`, updatedCount.toString());

  if (userUID) {
    const timestamp = new Date().toLocaleString(); // Get the current timestamp
    const steelOrderWithTimestamp = { ...steelOrder, timestamp }; // Include timestamp in the steelOrder object

    const profileRef = firebase.database().ref(`Steel Data/${userUID}`);
    profileRef.push(steelOrderWithTimestamp, (error) => {
      if (error) {
        console.error('Error adding data: ', error);
      } else {
        console.log('Data added successfully!');
        setSuccessMessage('Form submitted successfully!');

        // Clear the form after submission
        setSteelOrder({
          company: '',
          grade: '',
          order: '',
          address: '',
          payment: '',
          timestamp: '', // Reset the timestamp in the form state
        });

        // Clear the success message after 1 second
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      }
    });
  } else {
    console.error('User UID is not available.');
  }
};


  return (
    <div className="steel-Form">
      <h2 className="heading">Steel Order Form</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            name="company"
            value={steelOrder.company}
            onChange={handleChange}
            required
            placeholder='Company Name'
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="grade"
            value={steelOrder.grade}
            onChange={handleChange}
            required
            placeholder='Steel Grade'
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            name="order"
            value={steelOrder.order}
            onChange={handleChange}
            placeholder='Quantity(Tonne)'
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="address"
            value={steelOrder.address}
            onChange={handleChange}
            required
            placeholder='Site Address'
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            name="payment"
            value={steelOrder.payment}
            onChange={handleChange}
            required
            placeholder='Payment Details'
          />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
        {successMessage && <p className="success-message">{successMessage}</p>}
      </form>
    </div>
  );
}

export default SteelOrderForm;
