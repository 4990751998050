import React, { useState, useEffect } from 'react';
import './clientDetail.css';
import { FaCircleExclamation, FaMagnifyingGlass } from 'react-icons/fa6';
import firebase from '../Firebase/firebase';
import Profile from './Profile';
import { v4 as uuidv4 } from 'uuid';

const ClientDetail = () => {
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [suggestions, setSuggestions] = useState([
    'Anjali',
    'Deepakshi',
    'Rashil',
    'Rajni',
  ]);

  const [clientData, setClientData] = useState({
    company_name: '',
    balance: '',
    code: '',
    lastDispatch: '',
    brandSupply: '',
    pendingDetails: '',
    commodityServiced: '',
    address: '',
    bankDetails: {
      Ultratech: {
        beneficiaryName: '',
        accountNumber: '',
        ifscCode: '',
        bankName: '',
        branchName: '',
        showDetails: false,
      },
      Shree: {
        beneficiaryName: '',
        accountNumber: '',
        ifscCode: '',
        bankName: '',
        branchName: '',
        showDetails: false,
      },
      JSW: {
        beneficiaryName: '',
        accountNumber: '',
        ifscCode: '',
        bankName: '',
        branchName: '',
        showDetails: false,
      },
      ACC: {
        beneficiaryName: '',
        accountNumber: '',
        ifscCode: '',
        bankName: '',
        branchName: '',
        showDetails: false,
      },
      JKSuper: {
        beneficiaryName: '',
        accountNumber: '',
        ifscCode: '',
        bankName: '',
        branchName: '',
        showDetails: false,
      },
    },
    state: '',
    activeBlocked: '',
    orderExpected: '',
    paymentExpected: '',
  });

  const [dataFetched, setDataFetched] = useState(false); // Track if data is fetched

  useEffect(() => {
    const fetchData = async () => {
      const clientName = searchQuery.trim();
      if (clientName === '') {
        return; // No need to fetch if search query is empty
      }

      const currentUserId = firebase.auth().currentUser?.uid;
      if (!currentUserId) {
        alert('User not authenticated. Please log in.');
        return;
      }

      try {
        const snapshot = await firebase
          .database()
          .ref(`clients/${currentUserId}`)
          .orderByChild('name')
          .equalTo(clientName)
          .once('value');

        const data = snapshot.val();
        if (data) {
          const clientNodeId = Object.keys(data)[0];
          setClientData(data[clientNodeId]);
          setDataFetched(true); // Set dataFetched to true once data is successfully fetched
        } else {
          // If client not found, reset fields and set dataFetched to false
          setClientData({
            company_name: '',
            balance: '',
            code: '',
            lastDispatch: '',
            brandSupply: '',
            pendingDetails: '',
            commodityServiced: '',
            address: '',
            bankDetails: {
              Ultratech: {
                beneficiaryName: '',
                accountNumber: '',
                ifscCode: '',
                bankName: '',
                branchName: '',
                showDetails: false,
              },
              Shree: {
                beneficiaryName: '',
                accountNumber: '',
                ifscCode: '',
                bankName: '',
                branchName: '',
                showDetails: false,
              },
              JSW: {
                beneficiaryName: '',
                accountNumber: '',
                ifscCode: '',
                bankName: '',
                branchName: '',
                showDetails: false,
              },
              ACC: {
                beneficiaryName: '',
                accountNumber: '',
                ifscCode: '',
                bankName: '',
                branchName: '',
                showDetails: false,
              },
              JKSuper: {
                beneficiaryName: '',
                accountNumber: '',
                ifscCode: '',
                bankName: '',
                branchName: '',
                showDetails: false,
              },
            },
            state: '',
            activeBlocked: '',
            orderExpected: '',
            paymentExpected: '',
          });
          setDataFetched(false);
        }
      } catch (error) {
        console.error('Error fetching client data:', error);
        alert('Failed to fetch client data. Please try again.');
        setDataFetched(false);
      }
    };

    fetchData();
  }, [searchQuery]);

  const handleInputChange = (e) => {
    const input = e.target.value;
    setSearchQuery(input);
    const filteredSuggestions = suggestions.filter(suggestion =>
      suggestion.toLowerCase().includes(input.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSearchClick = () => {
    setShowSuggestions(!showSuggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setShowSuggestions(false);
  };

  const handleSaveClient = async () => {
    const currentUserId = firebase.auth().currentUser?.uid;
    if (!currentUserId) {
      alert('User not authenticated. Please log in.');
      return;
    }

    const clientName = searchQuery.trim(); // Trim any leading or trailing whitespace

    try {
      const snapshot = await firebase
        .database()
        .ref(`clients/${currentUserId}`)
        .orderByChild('name')
        .equalTo(clientName)
        .once('value');

      const data = snapshot.val();
      if (data) {
        const clientNodeId = Object.keys(data)[0];
        await firebase
          .database()
          .ref(`clients/${currentUserId}/${clientNodeId}`)
          .update({
            name: clientName,
            ...clientData,
          });
      } else {
        // Generate unique client node ID for each client
        const clientNodeId = uuidv4();

        await firebase
          .database()
          .ref(`clients/${currentUserId}/${clientNodeId}`)
          .set({
            name: clientName,
            ...clientData,
          });
        alert('New client information saved successfully!');
      }
    } catch (error) {
      console.error('Error saving client:', error);
      alert('Failed to save client information. Please try again.');
    }
  };

  const handleExclamationClick = () => {
    alert('Welcome to Altrange CRM! Here are some tips:\n\n- Manage your clients and contacts efficiently.\n- Use tags to categorize and filter clients.\n- Customize your dashboard with widgets.\n- Access reports and analytics to track performance.\n\nExplore more features and functionalities to optimize your workflow!');
  };

  const onHandleExclamationClick = () => {
    alert("Before you go, remember to save any unsaved changes!");
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSaveClient();
    }
  };

  useEffect(() => {
    // Check if user is authenticated
    const checkAuthStatus = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });

    return () => checkAuthStatus(); // Cleanup function
  }, []);

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      setAuthenticated(false);
      // Optionally redirect to login page here
    } catch (error) {
      console.error('Error logging out:', error);
      alert('Failed to log out. Please try again.');
    }
  };

  const handleBankDetailsClick = (brand) => {
    // Toggle display of bank details for the selected brand
    const updatedClientData = {
      ...clientData,
      bankDetails: {
        ...clientData.bankDetails,
        [brand]: {
          ...clientData.bankDetails[brand],
          showDetails: !clientData.bankDetails[brand]?.showDetails,
        },
      },
    };
    setClientData(updatedClientData);
  };

  const renderBankDetails = (brand) => (
    <div className='bank-details' key={brand}>
      <button onClick={() => handleBankDetailsClick(brand)} className='bank-details-btn'>
        {brand}
      </button>
      {clientData.bankDetails[brand]?.showDetails && (
        <div className='bank-details-info'>
          <p>Beneficiary Name: {clientData.bankDetails[brand]?.beneficiaryName}</p>
          <p>Account Number: {clientData.bankDetails[brand]?.accountNumber}</p>
          <p>IFSC Code: {clientData.bankDetails[brand]?.ifscCode}</p>
          <p>Bank Name: {clientData.bankDetails[brand]?.bankName}</p>
          <p>Branch Name: {clientData.bankDetails[brand]?.branchName}</p>
        </div>
      )}
    </div>
  );

  if (!authenticated) {
    // Redirect to login page if not authenticated
    return <Profile />;
  }
  
  return (
    <>
      <div className='clientdiv'>
        {/* section1 */}
        <div className='crmdiv'>
          <div className='CRM-container'>
            <h1>Altrange&nbsp;CRM</h1>
            <div className='userdiv'>
              <input type='text' placeholder='Username....' className='userdivInput'
              value={searchQuery}
              onChange={handleInputChange}
              onClick={handleSearchClick}
              />
            </div>
            <div className='excla' onClick={handleExclamationClick}>
              <FaCircleExclamation />
            </div>
          </div>
        </div>
        {/* section 2 */}

        <div className="searchbox-container">
          <div className="searchbox">
            <input
              type="text"
              placeholder="Search Client Name..."
              value={searchQuery}
              onChange={handleInputChange}
              onClick={handleSearchClick}
              onKeyPress={handleKeyPress}
              className='myserach'
            />
            <div className='search-fa'><FaMagnifyingGlass /></div>
            {showSuggestions && (
              <ul className="suggestions">
                {suggestions.map((suggestion, index) => (
                  <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                    {suggestion}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* section 3 */}

        <div className='crmbox_container'>
          <div className='crmboxflex1'>
            <h1>Dashboard</h1>
            <div className='crmaddress'>
              <textarea
                type="text"
                placeholder='Address*'
                className='crmInput'
                value={dataFetched ? `Address: ${clientData.address}` : ''}
                onChange={(e) => setClientData({ ...clientData, address: e.target.value })}
                disabled
              />
            </div>
            <div className='crmstate'>
              <textarea
                type="text"
                placeholder='State*'
                className='crmInput'
                value={dataFetched ? `State: ${clientData.state}` : ''}
                onChange={(e) => setClientData({ ...clientData, state: e.target.value })}
                disabled
              />
            </div>
            <div className='crmactive'>
              <textarea
                type="text"
                placeholder='Active/Blocked*'
                className='crmInput'
                value={dataFetched ? `Active/Blocked: ${clientData.activeBlocked}` : ''}
                onChange={(e) => setClientData({ ...clientData, activeBlocked: e.target.value })}
                disabled
              />
            </div>
          </div>
          <div className='crmboxflex2'>
            <div className='crmBalance crmcompany_name'>
              <textarea
                type="text"
                placeholder='Company_name*'
                className='crmInput'
                value={dataFetched ? `Company Name: ${clientData.company_name}` : ''}
                onChange={(e) => setClientData({ ...clientData, company_name: e.target.value })}
                disabled
              />
            </div>
            <div className='crmBalance'>
              <textarea
                type="text"
                placeholder='Balance*'
                className='crmInput'
                value={dataFetched ? `Balance: ${clientData.balance}` : ''}
                onChange={(e) => setClientData({ ...clientData, balance: e.target.value })}
                disabled
              />
            </div>
            <div className='crmlastDispatch'>
              <textarea
                type="text"
                placeholder='Last Dispatch Details*'
                className='crmInput'
                value={dataFetched ? `Last Dispatch: ${clientData.lastDispatch}` : ''}
                onChange={(e) => setClientData({ ...clientData, lastDispatch: e.target.value })}
                disabled
              />
            </div>
            <div className='crmpending'>
              <textarea
                type="text"
                placeholder='Pending Details*'
                className='crmInput'
                value={dataFetched ? `Pending Details: ${clientData.pendingDetails}` : ''}
                onChange={(e) => setClientData({ ...clientData, pendingDetails: e.target.value })}
                disabled
              />
            </div>
            <div className='crmbank'>
  <textarea
    type="text"
    placeholder='Bank Details*'
    className='crmInput'
    value={dataFetched ? `BankDetails : ` : ''}
    onClick={() => setShowBankDetails(!showBankDetails)}
    readOnly
  />
  {showBankDetails && (
    <div className='bank-details-container'>
      {Object.keys(clientData.bankDetails).map((brand) => (
        renderBankDetails(brand)
      ))}
    </div>
  )}
</div>
          </div>
          <div className='crmboxflex3'>
            <div className='crmcode'>
              <textarea
                type="text"
                placeholder='Code*'
                className='crmInput'
                value={dataFetched ? `Code: ${clientData.code}` : ''}
                onChange={(e) => setClientData({ ...clientData, code: e.target.value })}
                disabled
              />
            </div>
            <div className='crmbrandSupply'>
              <textarea
                type="text"
                placeholder='Brand Supply*'
                className='crmInput'
                value={dataFetched ? `Brand Supply: ${clientData.brandSupply}` : ''}
                onChange={(e) => setClientData({ ...clientData, brandSupply: e.target.value })}
                disabled
              />
            </div>
            <div className='crmcommodity'>
              <textarea
                type="text"
                placeholder='Commodity Serviced*'
                className='crmInput'
                value={dataFetched ? `Commodity Serviced: ${clientData.commodityServiced}` : ''}
                onChange={(e) => setClientData({ ...clientData, commodityServiced: e.target.value })}
                disabled
              />
            </div>
            <div className='crmorder'>
              <textarea
                type="text"
                placeholder='Order Expected*'
                className='crmInput'
                value={dataFetched ? `Order Expected: ${clientData.orderExpected}` : ''}
                onChange={(e) => setClientData({ ...clientData, orderExpected: e.target.value })}
                disabled
              />
            </div>
            <div className='crmpayment'>
              <textarea
                type="text"
                placeholder='Payment Expected*'
                className='crmInput'
                value={dataFetched ? `Payment Expected: ${clientData.paymentExpected}` : ''}
                onChange={(e) => setClientData({ ...clientData, paymentExpected: e.target.value })}
                disabled
              />
            </div>
          </div>
        </div>

        {/* section 4 */}
        <div className='switch_container'>
          <div className='crmSwitch'>
            <div className='crmExclaicon' onClick={onHandleExclamationClick}><FaCircleExclamation /></div>
            <p className='crmlogout'>Logout</p> 
            {/* <button type='submit' onClick={handleSaveClient} className='crmswitchbutn'> Save Changes </button> */}
            <button type='submit' onClick={handleLogout} className='crmswitchbutn'> Switch User </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientDetail;

