
import React, { useState, useRef,useEffect} from 'react';
import './Home.css'; // Import your CSS file
import { useNavigate } from 'react-router-dom'; 
import Notifications from './Notification'; 

function HomePage() {

   // Function to scroll to the top of the page
   const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
    });
  };


  useEffect(() => {
    scrollToTop();
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts

  
  const navigate = useNavigate(); 

  const [currentIndex, setCurrentIndex] = useState(0);
  const slideshowImages = [
    '/Images/acc.png',
    '/Images/ultratech.png',
    '/Images/shree.jpeg',
    '/Images/jsw.jpg',
    '/Images/jksupercement1 (3).jpg'
  ]; // Replace with your image URLs

  const dotClick = (index) => {
    setCurrentIndex(index);
  };

  const handleImageClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slideshowImages.length);
  };

  const handleScroll = (e) => {
    if (e.deltaY > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slideshowImages.length);
    } else if (e.deltaY < 0) {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? slideshowImages.length - 1 : prevIndex - 1));
    }
  };
  const slideshowRef = useRef(null);

  return (
    <div>
      {/* First Tile */}
      <br/><br/><Notifications />
      <div className="raw-materials-tile">
        <div className="image-block">
          <img src="/Images/Rwmat.jpg" alt="Raw Material" />
        </div>
        <div className="content">
          <h1>Order Raw materials with us!</h1>
          <h3>Where Quality Meets Affordability - Your One-Stop Steel and Cement Solution.</h3>
          <p>
          Discover the cornerstone of your construction projects with our premium steel and cement supplies.
           We bring you the strength and durability needed for your projects to stand the test of time.
            With our top-grade materials, you can build with confidence, knowing that every foundation, every structure, is backed by quality you can trust.
             Choose excellence, choose reliability – choose us for all your steel and cement needs.
          </p>
          <div className="home-buttons">
          <button className="home-button" onClick={() => navigate('/raw-materials')}>
  Order Here
</button>
          </div>
        </div>
      </div>
       <div className="slideshow-frame" onWheel={(e) => handleScroll(e)} ref={slideshowRef}>
       <h2 className="partners-header">Our Trusted Partners</h2>
      <div className="slideshow-container">
        {slideshowImages.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            className={`${index === currentIndex ? 'active' : ''}`}
            onClick={handleImageClick}
          />
        ))}
      </div>
      <div className="dot-container">
        {slideshowImages.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active-dot' : ''}`}
            onClick={() => dotClick(index)}
          ></span>
        ))}
      </div>
    </div>
          
      {/* Second Tile */}
      <div className="finance">
        <div className="content">
          <h1>Finance your Dreams with us!</h1>
          <p>
          At Altrange, we understand that every successful venture begins with a solid financial foundation.
           Whether you're a startup looking for initial funding or an established business seeking to expand, we offer tailored financing solutions to fuel your growth.
            Our team of financial experts is committed to helping you secure the capital you need, with flexible terms and competitive rates.
             With our support, you can unlock opportunities, seize the future, and achieve your financial goals.
              Trust us as your financial partner, and we'll empower your journey to success.
          </p>
          <div className="home-buttons">
            <button className="home-button" onClick={() => navigate('/finance')}>
              Enter to know more
            </button>
          </div>
        </div>
        <div className="image-container">
          <img src="/Images/finance.png" alt="finance" />
        </div>
      </div>
  
      {/* Third Tile - Machinery */}
      <div className="machinery">
        <div className="image-container">
          <img src="/Images/machinery.jpg" alt="Machinery" />
        </div>
        <div className="content">
          <h1>Explore Our Machinery Services</h1>
          <p>
          Unlock the potential of your construction projects with our top-tier machinery solutions.
           From excavators to loaders, we offer a wide selection of reliable and robust equipment that ensures your projects are completed efficiently and on time.
            We're here to power your construction vision.
          </p>
          <div className="home-buttons">
            <button className="home-button" onClick={() => navigate('/machinerypage')}>
              Order Machinery
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;

