import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaXTwitter, FaYoutube} from 'react-icons/fa6';

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const companyName = "Altrange Private Limited";

  return (
    <footer className="bg-gray-900 text-white p-4">
      {/* Social Media Icons */}
      <div className="flex items-center justify-center">
        <a href="https://www.facebook.com/profile.php?id=100080426424193" target="_blank" className="text-3xl fb">
      <FaFacebook />
    </a>
    <a href="https://www.instagram.com/" target="_blank" className="text-3xl insta">
      <FaInstagram />
    </a>
    <a href="https://twitter.com/altrangedotcom" target="_blank" className="text-3xl tweet">
      <FaXTwitter />
    </a>
    <a href="https://www.youtube.com/@Altrange_official" target="_blank" className="text-3xl youtube">
      <FaYoutube />
    </a>
      </div>
     
      <div className="app-link">
        <div className="app-container">
          <div className="images-container">
          <div>
      <Link to="/InteractWhatsapp" target="_blank"  rel="noopener noreferrer">
          <div className='whatsapp'>
             <img src="/Images/whatsapp.png" alt="whatsapp" className="whatsapp-icon" />
           
            <div className='contact-whatsapp'>
            <p style={{marginTop:'8px'}}> Connect on Whatsapp! </p>
            <p className='number' >9592080108</p>
            </div>
          </div>
          </Link>
      </div>

            <Link to="https://bit.ly/Altrange" target="_blank"  rel="noopener noreferrer">
            <div>
              <img src="/Images/google.png" alt="Download on Play Store" className="app-icon" />
              </div>
            </Link>
            <Link to="https://apple.co/3PBsiBs" target="_blank"   rel="noopener noreferrer">
            <div>
              <img src="/Images/apple.png" alt="Download on App Store" className=" apple-icon" />
              </div>
            </Link>
          </div>
        </div>
      </div>

      
      {/* Navigation Items */}
      
      <div className="footer-nav-bar">
        <ul>
          <li>
            <Link to="/aboutus" className="hover:text-blue-400">About Us</Link>
          </li>
          <li>
            <Link to="/termsofuse" className="hover:text-blue-400">Terms of Use</Link>
          </li>
          <li>
            <Link to="/ourservices" className="hover:text-blue-400">Our Services</Link>
          </li>
          <li>
            <Link to="/privacypolicy" className="hover:text-blue-400">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/processofunderstanding" className="hover:text-blue-400">Process Understanding</Link>
          </li>
          <li>
            <Link to="/return" className="hover:text-blue-400">Return Policy</Link>
          </li>
          <li>
            <Link to="/contactus" className="hover:text-blue-400">Contact Us</Link>
          </li>
          
          
        </ul>
      </div>
      
      {/* Current Year and Company Name */}
      <div className="company">
        &copy; {currentYear} {companyName}
      </div>
    </footer>
  );
}
