import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase'; // Import Firebase configuration
import './Ratelist.css'; // Import your CSS file

const FirebaseDataDisplay = () => {
  const [siteDetails, setSiteDetails] = useState([]); // Store site details by siteId
  const [siteRates, setSiteRates] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = firebase.auth().currentUser;
        if (!currentUser) {
          console.error('No authenticated user found');
          setLoading(false);
          return;
        }

        const userId = currentUser.uid;
        const siteAddressRef = firebase.database().ref(`SiteAddress/${userId}`);
        const siteRateRef = firebase.database().ref(`SiteRate/${userId}`);

        // Fetch initial data
        const [siteAddressSnapshot, siteRateSnapshot] = await Promise.all([
          siteAddressRef.once('value'),
          siteRateRef.once('value'),
        ]);

        const siteAddressData = siteAddressSnapshot.val() || {};
        const siteRateData = siteRateSnapshot.val() || {};

        // Process site addresses and keep track of duplicates
        const siteDetailsList = [];
        const addressCount = {}; // Track occurrences of each address

        Object.entries(siteAddressData).forEach(([siteId, siteData]) => {
          if (siteData && siteData.address) {
            const address = siteData.address;

            // Count occurrences of the same address
            if (!addressCount[address]) {
              addressCount[address] = 1;
            } else {
              addressCount[address]++;
            }

            // Append the count to repeated addresses
            const numberedAddress = addressCount[address] > 1
              ? `${address} (${addressCount[address]})`
              : address;

            siteDetailsList.push({
              siteId,
              address: numberedAddress, // Use the numbered address if necessary
            });
          }
        });

        setSiteDetails(siteDetailsList); // Store site details by siteId

        // Process site rates
        const formattedRates = {};

        Object.entries(siteRateData).forEach(([brand, rates]) => {
          Object.entries(rates).forEach(([siteId, rateData]) => {
            const siteDetail = siteDetailsList.find((site) => site.siteId === siteId);
            if (siteDetail) {
              if (!formattedRates[siteId]) {
                formattedRates[siteId] = {};
              }

              if (rateData && rateData.rate) {
                formattedRates[siteId][brand] = rateData.rate;
              }
            }
          });
        });

        setSiteRates(formattedRates);
        setLoading(false);

        // Set up real-time listeners
        const addressListener = siteAddressRef.on('value', (snapshot) => {
          const siteAddressData = snapshot.val() || {};
          const updatedSiteDetails = [];
          const addressCount = {};

          Object.entries(siteAddressData).forEach(([siteId, siteData]) => {
            if (siteData && siteData.address) {
              const address = siteData.address;

              if (!addressCount[address]) {
                addressCount[address] = 1;
              } else {
                addressCount[address]++;
              }

              const numberedAddress = addressCount[address] > 1
                ? `${address} (${addressCount[address]})`
                : address;

              updatedSiteDetails.push({
                siteId,
                address: numberedAddress,
              });
            }
          });

          setSiteDetails(updatedSiteDetails); // Update site details
        });

        const rateListener = siteRateRef.on('value', (snapshot) => {
          const siteRateData = snapshot.val() || {};
          const formattedRates = {};

          Object.entries(siteRateData).forEach(([brand, rates]) => {
            Object.entries(rates).forEach(([siteId, rateData]) => {
              const siteDetail = siteDetailsList.find((site) => site.siteId === siteId);
              if (siteDetail) {
                if (!formattedRates[siteId]) {
                  formattedRates[siteId] = {};
                }

                if (rateData && rateData.rate) {
                  formattedRates[siteId][brand] = rateData.rate;
                }
              }
            });
          });

          setSiteRates(formattedRates);
        });

        // Cleanup on unmount
        return () => {
          siteAddressRef.off('value', addressListener);
          siteRateRef.off('value', rateListener);
        };
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInfoIcon = () => {
    alert("View and manage your existing construction sites with current rates and details.");
  };

  return (
    <div className="container">
      <div className='existingsite-data'>
        <span>Your existing sites</span>
        <img
          src={`${process.env.PUBLIC_URL}/Images/info-icon.png`}
          onClick={handleInfoIcon}
          className='existing-info-icon'
          alt="Info Icon"
        />
      </div>

      <div className="addresses-container">
        {loading ? (
          <div>Loading...</div>
        ) : (
          siteDetails.length === 0 ? (
            <p>Please add your site in "New Site".</p>
          ) : (
            siteDetails.map(({ siteId, address }, index) => (
              <div className="address-frame" key={siteId}>
                <div className="address">
                  <h3>Site Address {index + 1}</h3>
                  <p>Address: {address}</p>
                </div>
                <div className="rates">
                  <h3>Rates &#x20B9;</h3>
                  <ul>
                    {Object.entries(siteRates[siteId] || {}).map(([brand, rate]) => {
                      if (rate && rate !== '0.00') {
                        return (
                          <li key={brand}>
                            <strong>{brand}:</strong> <span style={{ color: 'blue' }}>{rate}</span>
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ul>
                </div>
              </div>
            ))
          )
        )}
      </div>
    </div>
  );
};

export default FirebaseDataDisplay;
