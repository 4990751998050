import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './Profile.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Profile = ({}) => {
  const [userData, setUserData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newCompanyName, setNewCompanyName] = useState('');
  const [showProfileCompletionMsg, setShowProfileCompletionMsg] = useState(false);
  const user = firebase.auth().currentUser;
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (user) {
      const userRef = firebase.database().ref('profile/' + user.uid);
      userRef.on('value', (snapshot) => {
        const data = snapshot.val();
        setUserData(data);

        const isProfileEmpty = !data || !data.name || data.name === 'New User';
        setShowProfileCompletionMsg(isProfileEmpty);
      });
    }
  }, [user]);

  const handleEditProfile = () => {
    setIsEditing(true);
    setNewName(userData.name);
    setNewEmail(userData.email);
    setNewCompanyName(userData.company_name);
  };

  const handleSaveProfile = () => {
    const userRef = firebase.database().ref('profile/' + user.uid);
    userRef.update({
      name: newName,
      email: newEmail,
      company_name: newCompanyName,
    });
    setIsEditing(false);
  };

  const handleDeleteAccount = () => {
    navigate('/delete-account'); // Use navigate to redirect to delete account page
  };

  const handleLogout = () => {
    firebase.auth().signOut();
  };

  return (
    <div className="profile-container">
      {showProfileCompletionMsg && (
        <p className="profile-completion-msg">Please! fill here your account details.</p>
      )}
      <div className="profile-frame">
        <div className="profile-info">
          <div className="profile-image-container" onClick={handleEditProfile}>
            <img
              src="/Images/profile.jpg"
              alt="Profile"
              className="profile-image"
            />
          </div>
  {isEditing ? ( <>
    <div className="input-label">
      <label htmlFor="name">Name:</label>
      <input
        type="text"
        id="name"
        placeholder="Your name"
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
      />
    </div>
    <div className="input-label">
      <label htmlFor="email">Email:</label>
      <div className="input-container">
        <input
          type="email"
          id="email"
          placeholder="abc123@mail.com"
          value={newEmail}
          onChange={(e) => setNewEmail(e.target.value)}
        />
      </div>
    </div>
    <div className="input-label">
      <label htmlFor="companyName">Company Name:</label>
      <input
        type="text"
        id="companyName"
        placeholder="Company Name"
        value={newCompanyName}
        onChange={(e) => setNewCompanyName(e.target.value)}
      />
    </div>
    <button onClick={handleSaveProfile} className="save">Save</button>
    <button onClick={handleDeleteAccount} className="delete">Delete Account</button>
  </>
          ) : (
            <div className="profile-details">
            <p><strong>Name:</strong> <span>{userData.name}</span></p>
            <p><strong>Email:</strong> <span>{userData.email}</span></p>
            <p><strong>Company Name:</strong> <span>{userData.company_name}</span></p>
            <p><strong>Contact No. :</strong> <span>{userData.phone}</span></p>
            <button onClick={handleLogout} className="logout">Logout</button>
          </div>
          
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
