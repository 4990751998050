import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './JobForm.css';

function JobForm() {
  const formId = 'job';
  const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
  const maxSubmissions = 3;

  const [jobApplication, setJobApplication] = useState({
    name: '',
    number: '',
    email: '',
    resume: null,
    timestamp: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [setFormSubmitted] = useState(false);
  const [successfulSubmissions, setSuccessfulSubmissions] = useState(
    parseInt(localStorage.getItem(`submissionCount_${formId}`)) || 0
  );

  useEffect(() => {
    const currentDate = new Date().toISOString().slice(0, 10);
    const storedDate = localStorage.getItem('date');

    if (storedDate !== currentDate) {
      localStorage.setItem('date', currentDate);
      localStorage.setItem(`submissionCount_${formId}`, '0');
      setSuccessfulSubmissions(0);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobApplication({ ...jobApplication, [name]: value });
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];

    if (file && file.size > maxFileSize) {
      alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
      e.target.value = null;
      return;
    }

    const storageRef = firebase.storage().ref();
    const user = firebase.auth().currentUser;

    if (user) {
      const fileRef = storageRef.child(`Resume/${fileType}_${file.name}`);

      fileRef.put(file).on(
        'state_changed',
        (snapshot) => console.log(`Upload is ${(snapshot.bytesTransferred / snapshot.totalBytes) * 100}% done`),
        (error) => {
          console.error('Error uploading file:', error);
          alert('Error uploading file. Please try again.');
        },
        () => console.log('File uploaded successfully')
      );
    }
  };

  const clearForm = () => setJobApplication({ name: '', number: '', email: '', resume: null });

  const getCurrentTimestamp = () => new Date().toLocaleString();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (successfulSubmissions >= maxSubmissions) {
      setSuccessMessage('Your submission quota for today is finished');
      return;
    }

    const updatedCount = successfulSubmissions + 1;
    setSuccessfulSubmissions(updatedCount);
    localStorage.setItem(`submissionCount_${formId}`, updatedCount.toString());

    const requiredFields = ['name', 'number', 'email'];

    if (requiredFields.every((field) => jobApplication[field])) {
      const database = firebase.database();
      const newSubmissionRef = database.ref('Job Candidates').push();

      jobApplication.timestamp = getCurrentTimestamp();

      newSubmissionRef.set(jobApplication, (error) => {
        if (!error) {
          setFormSubmitted(true);
          setSuccessMessage('Form submitted successfully! We will contact you soon.');
          setTimeout(() => {
            setSuccessMessage('');
            setFormSubmitted(false);
          }, 2000);
        }
      });

      clearForm();
    }
  };

  return (
    <div className="job-form-container">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Candidate Name:</label>
          <input type="text" name="name" value={jobApplication.name} onChange={handleChange} placeholder="Your name" required />
        </div>
        <div className="form-group">
          <label>Phone Number:</label>
          <input type="text" name="number" value={jobApplication.number} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input type="text" name="email" placeholder="abc@mail.com" value={jobApplication.email} onChange={handleChange} />
        </div>
        <div className="form-group">
          <label>Upload Resume:</label>
          <input type="file" name="resume" onChange={(e) => handleFileChange(e, 'PO')} />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
        {successMessage && <p className="success-message">{successMessage}</p>}
      </form>
    </div>
  );
}

export default JobForm;
