import React, { useState} from 'react';
import firebase from '../Firebase/firebase';
import './FinanceForm.css';

function LoanForm() {
 


  const [loanData, setLoanData] = useState({
    loanType: '',
    repayment: '',
    loanAmount: '',
    name: '',
    email: '',
    number: '',
    address: '',
    aadhar: '',
    panNumber: '',
    gstinNumber: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLoanData({ ...loanData, [name]: value });
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
  
    if (file) {
      // Check file size
      if (file.size > maxSize) {
        console.error('File size exceeds the limit of 5MB.');
        // You can set an error state or display an error message to the user
        alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
        e.target.value = null; // Reset the input to allow selecting a new file
        return;
      }
  
      const storageRef = firebase.storage().ref();
      const user = firebase.auth().currentUser;
  
      if (user) {
        const userUID = user.uid;
        const fileRef = storageRef.child(`Finance/${userUID}/${fileType}_${file.name}`);
  
        // Upload the file to Firebase Storage
        const uploadTask = fileRef.put(file);
  
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Progress function (optional)
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error('Error uploading file:', error);
            // You can set an error state or display an error message to the user
            alert('Error uploading file. Please try again.');
          },
          () => {
            // Handle successful uploads on complete
            console.log('File uploaded successfully');
          }
        );
      }
    }
  };
  
  
  const checkPANFormat = (value) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(value);
  };

  const checkGSTINFormat = (value) => {
    const gstinRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/;
    return gstinRegex.test(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    // Check PAN format
    if (loanData.panNumber && !checkPANFormat(loanData.panNumber)) {
      setError('Invalid PAN number format');
      return;
    }

    // Check GSTIN format
    if (loanData.gstinNumber && !checkGSTINFormat(loanData.gstinNumber)) {
      setError('Invalid GSTIN number format');
      return;
    }

    const user = firebase.auth().currentUser; // Get the currently signed-in user
    if (user) {
      const userUID = user.uid; // Get the user's UID

      // Store the loan application data in the user's profile under their UID
      const financeRef = firebase.database().ref(`Finance/${userUID}`);
      financeRef.push(loanData);

      setSuccessMessage('Loan application submitted successfully');
    } else {
      setError('An error occurred while submitting the loan application');
    }
       // Clear the form after submission
       setLoanData({
        loanType: '',
        repayment: '',
        loanAmount: '',
        name: '',
        email: '',
        number: '',
        address: '',
        aadhar: '',
        panNumber: '',
        gstinNumber: '',
      });

      // Clear the success message after 1 second
      setTimeout(() => {
        setSuccessMessage('');
      }, 1000);
  };

  return (
    <div className="loan-form-container">
      <h1 className="title">Loan Application Form</h1>
      <form className="loan-form" onSubmit={handleSubmit}>
        <label className="loan-label">Loan Type:</label>
        <select
          className="loan-select"
          name="loanType"
          value={loanData.loanType}
          onChange={handleInputChange}
        >
          <option value="">Select Loan Type</option>
          <option value="Personal Loan">Personal Loan</option>
          <option value="Home Loan">Home Loan</option>
          <option value="Auto Loan">Invoice Financing</option>
          <option value="Auto Loan">Equipment Financing</option>
          <option value="Auto Loan">Buisness Loan for Women</option>
          <option value="Auto Loan">Term Loan</option>
          {/* Add more loan types as needed */}
        </select>

        <label className="loan-label">Repayment:</label>
        <select
          className="loan-select"
          name="repayment"
          value={loanData.repayment}
          onChange={handleInputChange}
        >
          <option value="">Select Repayment period</option>
          <option value="6-months">6 months</option>
          <option value="12-months">12 months</option>
          <option value="24-months">24 months</option>
          <option value="24-months">36 months</option>
          <option value="24-months">48 months</option>
          <option value="24-months">60 months</option>
          {/* Add more repayment options as needed */}
        </select>

        <label className="loan-label">Loan Amount:</label>
        <input
          className="loan-input"
          type="text"
          name="loanAmount"
          value={loanData.loanAmount}
          onChange={handleInputChange}
          required
        />

        <label className="loan-label">Company Name:</label>
        <input
          className="loan-input"
          type="text"
          name="name"
          value={loanData.name}
          onChange={handleInputChange}
          required
        />

        <label className="loan-label">Email:</label>
        <input
          className="loan-input"
          type="email"
          name="email"
          value={loanData.email}
          onChange={handleInputChange}
          required
        />

        <label className="loan-label">Contact number:</label>
        <input
          className="loan-input"
          type="tel"
          name="number"
          value={loanData.number}
          onChange={handleInputChange}
          required
        />

<label className="loan-label">Address:</label>
        <input
          className="loan-input"
          type="text"
          name="address"
          placeholder="Enter Address"
          value={loanData.address}
          onChange={handleInputChange}
          required
        />


        <label className="loan-label">Aadhar number:</label>
        <input
          className="loan-input"
          type="text"
          name="aadhar"
          placeholder="1234 5678 1234"
          value={loanData.aadhar}
          onChange={handleInputChange}
          required
        />

        <label className="loan-label">PAN Number:</label>
        <input
          className="loan-input"
          type="text"
          name="panNumber"
          placeholder="ABCDE1234F"
          value={loanData.panNumber}
          onChange={handleInputChange}
        />

        <label className="loan-label">GSTIN Number:</label>
        <input
          className="loan-input"
          type="text"
          name="gstinNumber"
          placeholder="29AAAAA1234C1ZF"
          value={loanData.gstinNumber}
          onChange={handleInputChange}
        />

        <label className="loan-label">Aadhar Card:</label>
        <input
          className="loan-input"
          type="file"
          onChange={(e) => handleFileChange(e, 'Aadhar')}
          required
        />

        <label className="loan-label">Pan Card:</label>
        <input
          className="loan-input"
          type="file"
          onChange={(e) => handleFileChange(e, 'Pan')}
          required
        />

<label className="loan-label">GSTIN Certificate:</label>
        <input
          className="loan-input"
          type="file"
          onChange={(e) => handleFileChange(e, 'Gstin')}
          required
        />

<label className="loan-label">Govt Contract:</label>
        <input
          className="loan-input"
          type="file"
          onChange={(e) => handleFileChange(e, 'Contract')}
          required
        />

        <button className="loan-button" type="submit">
          Submit
        </button>
        {successMessage && <p className="success-message">{successMessage}</p>}
      {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
}

export default LoanForm;