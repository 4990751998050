import React, { useState, useEffect, useInsertionEffect } from 'react';
import firebase from '../Firebase/firebase';
import { useNavigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const [user, setUser] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState('+91');
  const [phone, setPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const navigate = useNavigate();
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [captchaCompleted, setCaptchaCompleted] = useState(false);

  const adminCredentials = {
    phone: '+916757575757',
    otp: '878787',
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const checkIfUserExists = async (phoneNumber) => {
    const snapshot = await firebase.database().ref('profile').orderByChild('phone').equalTo(phoneNumber).once('value');
    return snapshot.exists();
  };


  useEffect(() => {
    initializeRecaptcha();
  }, []);

  const initializeRecaptcha = () => {
    console.log('Initializing RecaptchaVerifier...');
    const recaptchaContainer = document.getElementById('recaptcha-container');
    if (recaptchaContainer) {
      try {
        const verifier = new firebase.auth.RecaptchaVerifier(recaptchaContainer, {
          size: 'normal',
          callback: () => {
            setCaptchaVerified(true);
            handleSendOtp();
          },
          timeout: 10000,
        });
        setRecaptchaVerifier(verifier);
        console.log('RecaptchaVerifier initialized successfully!');
      } catch (error) {
        console.error("Error initializing reCAPTCHA:", error);
      }
    } else {
      console.error("Error: Recaptcha container not found");
    }
  };
  const handleSendOtp = (e) => {
     e.preventDefault()
    if (!phone) {
      setErrorMessage('Please enter your phone number.');
      return;
    }

    const fullPhoneNumber = `${selectedCountry}${phone}`;

    if (fullPhoneNumber === adminCredentials.phone) {
      // If admin's phone number, automatically set the admin OTP
      setOtp(adminCredentials.otp);
    }
    checkIfUserExists(fullPhoneNumber)
      .then((userExists) => {
        setIsNewUser(!userExists);
        return firebase.auth().signInWithPhoneNumber(fullPhoneNumber, recaptchaVerifier);
      })
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setErrorMessage('');
        // Set captchaCompleted to true when the captcha is successfully completed
        setCaptchaCompleted(true);
        console.log('OTP sent successfully!');
      })
      .catch((error) => {
        handleAuthError(error);
      });

  };

  const handleVerifyOtp = () => {
    if (!otp) {
      setErrorMessage('Please enter the OTP.');
      return;
    }

    if (!window.confirmationResult || !window.confirmationResult.verificationId) {
      setErrorMessage('Error verifying OTP. Please try again.');
      return;
    }

    const credential = firebase.auth.PhoneAuthProvider.credential(
      window.confirmationResult.verificationId,
      otp
    );

    firebase
      .auth()
      .signInWithCredential(credential)
      .then((result) => {
        handleUserProfile(result.user);
      })
      .catch((error) => {
        handleAuthError(error);
      });
  };

  const handleUserProfile = (user) => {
    const currentUserID = user.uid;
    const profileRef = firebase.database().ref(`profile/${currentUserID}`);
    const accountDetailsRef = firebase.database().ref(`AccountDetails/${currentUserID}`);

    profileRef.once('value', (snapshot) => {
      if (!snapshot.exists()) {
        profileRef.set({
          name: user.displayName || 'New User',
          phone: user.phoneNumber,
          email: user.email || 'user@mail.com',
          company_name: user.company_name || 'New User',
          dispatch: user.dispatch || 'null',
          dispatch_more: user.dispatchmore || 'null',
          pending: user.pending || 'null',
          pending_more: user.pendingmore || 'null',
          ult_amt: user.balance || 'null',
          shr_amt: user.sbalance || 'null',
          jsw_amt: user.abalance || 'null',
          m_status: user.status || 'null',
          loan_status: user.loan_status || 'null',
          note: user.note || 'welcome to Altrange!',
        });
      }

      accountDetailsRef.once('value', (snapshot) => {
        if (!snapshot.exists()) {
          accountDetailsRef.set({
            Acc: { b_name: '', account: '', ifsc: '', branch: '', site: '' },
            Shree: { b_name: '', account: '', ifsc: '', branch: '', site: '' },
            Ultratech: { b_name: '', account: '', ifsc: '', branch: '', site: '' },
          });
        }
        navigate('/profile');
      });
    });
  };

  const handleAuthError = (error) => {
    console.error('Authentication Error:', error);

    switch (error.code) {
      case 'auth/invalid-phone-number':
        setErrorMessage('Invalid phone number format. Please check and try again.');
        break;
      case 'auth/invalid-verification-code':
        setErrorMessage('Invalid OTP. Please enter the correct OTP.');
        break;
      default:
        setErrorMessage('Error during authentication. Please try again later.');
    }
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, '').slice(0, 10);
    setPhone(input);
  };

  const countryCodes = [
    { code: '+91', name: 'India' },
    { code: '+95', name: 'Myanmar' },
    { code: '+977', name: 'Nepal' },
    { code: '+94', name: 'Sri Lanka' },
    { code: '+880', name: 'Bangladesh' },
    { code: '+86', name: 'China' },
    { code: '+66', name: 'Thailand' },
    { code: '+62', name: 'Indonesia' },
    { code: '+60', name: 'Malaysia' },
    { code: '+65', name: 'Singapore' },
    { code: '+1', name: 'United States / USA' },
    { code: '+44', name: 'United Kingdom' },
    { code: '+49', name: 'Germany' },
    { code: '+33', name: 'France' },
    { code: '+7', name: 'Russia' },
    { code: '+81', name: 'Japan' },
    { code: '+82', name: 'South Korea' },
    { code: '+34', name: 'Spain' },
    { code: '+55', name: 'Brazil' },
    { code: '+54', name: 'Argentina' },
    { code: '+98', name: 'Iran' },
    { code: '+966', name: 'Saudi Arabia' },
    { code: '+84', name: 'Vietnam' },
    { code: '+63', name: 'Philippines' },
    { code: '+39', name: 'Italy' },
   { code: '+31', name: 'Netherlands' },
    { code: '+41', name: 'Switzerland' },
    { code: '+46', name: 'Sweden' },
    { code: '+47', name: 'Norway' }
    // Add more country codes as needed
  ];

  return (
    <div className='Login_bg'>
    <div className="frame">
      <div className="login-container">
        <h1>Login or Signup</h1>
        {user ? (
          <div>
            <button onClick={() => firebase.auth().signOut()}>Logout</button>
          </div>
        ) : (
          <div className="login-form">
            <div className="input-group">
            <label htmlFor="phoneNumber" style={{ fontWeight: 'bold' }}>Phone Number</label>
              <div className="phone-input">
                <select
                  id="countryCode"
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  className="custom-select"
                >
                  {countryCodes.map((country) => (
                    <optgroup label={country.name} key={country.code} className="optgroup">
                      <option value={country.code} className="option">
                        {country.code}
                      </option>
                    </optgroup>
                  ))}
                </select>

                <input
                  type="tel"
                  id="phoneNumber"
                  value={phone}
                  onChange={handlePhoneChange}
                  placeholder="Enter phone number"
                />
              </div>
            </div>

            <div className="input-group">
              <button onClick={handleSendOtp}  className="otp-button">Request OTP</button>
            </div>

            {!captchaCompleted && (
  <div id="recaptcha-container"></div>
)}

{captchaVerified && (
  <div className="input-group">
    <label htmlFor="otp" style={{ fontWeight: 'bold' }}>Enter OTP</label>
    <input type="text" id="otp" value={otp} onChange={(e) => setOtp(e.target.value)} />
    <button onClick={handleVerifyOtp} className="otp-button">Verify OTP</button>
  </div>
)}

            <p>
              By continuing, you agree to our <a href="/termsofuse">Terms of Use</a> and{' '}
              <a href="/privacypolicy">Privacy Policy</a>.
            </p>
            {errorMessage && (
              <div className="input-group error-message">
                <p>{errorMessage}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
    </div>
  );
};

export default Login;