import React from 'react';
import { useNavigate } from 'react-router-dom';
import firebase from '../Firebase/firebase';
import "./DeleteAccount.css";

const DeleteAccount = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const user = firebase.auth().currentUser;

  const handleConfirmDelete = () => {
    const userRef = firebase.database().ref('profile/' + user.uid);

    userRef
      .remove()
      .then(() => {
        return firebase.auth().signOut();
      })
      .then(() => {
        alert('Account deleted successfully.');
        navigate('/'); // Use navigate instead of history.push
      })
      .catch((error) => {
        console.error('Error deleting account:', error);
        alert('Failed to delete account. Please try again.');
      });
  };

  const handleCancel = () => {
    navigate('/profile'); // Use navigate instead of history.push
  };

  return (
    <div className="delete-account-container">
      <h1 className='delete-h1'>Delete Account</h1>
      
      <div className='instructions'>
        <h2 className='instructions-title'>How to Delete Your Account:</h2>
        <ul className='instru-ctions-list'>
          <li>Click the <strong>"Yes, Delete Account"</strong> button below if you are sure you want to delete your account.</li>
          <li>All your personal information and account details will be permanently removed.</li>
          <li>You will be logged out of your account after the deletion process is completed.</li>
          <li>If you change your mind, click <strong>"Cancel"</strong> to go back to your profile.</li>
        </ul>
      </div>
      <p className='delete-p'>
        Are you sure you want to delete your account? This action cannot be undone.
      </p>
      <button onClick={handleConfirmDelete} className="confirm-delete">Yes, Delete Account</button>
      <button onClick={handleCancel} className="cancel-delete">Cancel</button>
    </div>
  );
};

export default DeleteAccount;
