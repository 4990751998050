import React, { useState, useEffect } from 'react';
import './Finance.css';
import FinanceForm from '../Forms/FinanceForm';
import EnquiryForm from '../Forms/EnquiryForm';
import firebase from '../Firebase/firebase';


const Finance = () => {
 // Function to scroll to the top of the page
 const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Smooth scrolling animation
  });
};


useEffect(() => {
  scrollToTop();
}, []); // Empty dependency array ensures this effect runs only once, when the component mounts


  const [showForm, setShowForm] = useState(true);
  const [selectedForm, setSelectedForm] = useState('enquiry');
  const [showImages, setShowImages] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [balanceData, setBalanceData] = useState({});
  const [showStatusCard, setShowStatusCard] = useState(false);
  const [showRequirements, setShowRequirements] = useState(false);
  const totalImages = 2;

  const user = firebase.auth().currentUser;

  useEffect(() => {
    const balanceRef = firebase.database().ref(`profile/${user.uid}/loan_status`);

    const fetchBalanceData = () => {
      balanceRef.on('value', (balanceSnapshot) => {
        const balanceData = balanceSnapshot.val() || {};
        setBalanceData(balanceData);
      });
    };

    fetchBalanceData();

    return () => {
      balanceRef.off('value');
    };
  }, [user.uid]);

  useEffect(() => {
    const formContainer = document.getElementById('form-container');
    formContainer && formContainer.scrollIntoView();
  }, []);

  useEffect(() => {
    let intervalId;

    const startAutoScroll = () => {
      intervalId = setInterval(() => {
        setSlideIndex((prevIndex) => (prevIndex + 1) % totalImages);
      }, 1000); // Change image every 3 seconds (adjust the interval duration as needed)

      return () => clearInterval(intervalId);
    };

    startAutoScroll();
  }, [totalImages]);

  const handleOrderStatusClick = () => {
    setShowStatusCard(true);
    setSelectedForm('');
    setShowRequirements(false);
    setShowImages(false);
  };

  const handleFormClick = (formType) => {
    setShowStatusCard(false);
    setSelectedForm(formType);
    setShowRequirements(false);
    setShowImages(false);
  };

  const toggleImages = () => {
    setShowImages(!showImages);
  };
  

  const toggleRequirements = () => {
    setShowRequirements(!showRequirements);
    setShowStatusCard(false);
    setSelectedForm('');
    setShowImages(false);
  };
  

  return (
    <div className="Finance">
      <div className="finance-block">
        <div className="header-block">
          <h1>Finance your needs here with us!</h1>
          <div className="finance-nav-bar">
            <ul>
              <li onClick={toggleImages}>Our Partners</li>
              <li onClick={() => handleFormClick('finance')}>Apply Now</li>
              <li onClick={() => handleFormClick('enquiry')}>Any Query</li>
              <li onClick={handleOrderStatusClick}>Check status</li>
              <li onClick={toggleRequirements}>Documents</li>
            </ul>
          </div>
          <h2>
            Quick and easy approval process.<br />
            Low-interest rates that save you money.<br />
            Flexible repayment options tailored to your needs.
          </h2>
          {showImages && (
            <div className="image-container">
              <img src="Images/utkarsh.jpg" alt="partners" />
            </div>
          )}
        </div>
        <div className="finance-form-block block-form-finance" id="form-container">
          {showStatusCard && (
            <div className="status-card">
              <h2>Your Application Status</h2>
              <div className="status-data">
                <pre>{JSON.stringify(balanceData, null, 2)}</pre>
              </div>
            </div>
          )}
          {showForm && selectedForm === 'finance' && <FinanceForm />}
          {showForm && selectedForm === 'enquiry' && <EnquiryForm />}
          {showRequirements && (
  <div className="requirements">
    <h2>Required Documents</h2>
    <ul>
      <li>Allotment Letter</li>
      <li>GST Copy</li>
      <li>Pan Copy</li>
      <li>Site Running Pics</li>
      <li>Balance Sheet Last 2 yrs</li>
      <li>Passport size Photo</li>
      {/* Add more items */}
    </ul>
  </div>
)}

        </div>
      </div>
    </div>
  );
};

export default Finance;
