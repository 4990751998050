import './OurServices.css';
import React, { useEffect } from 'react';
import LazyLoad from 'react-lazyload';


function OurServices() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <div className="services">
            <h1>Our Services</h1>
            {/* Cement section */}
            <div className='container-fluid'>
                <div className='row'>
                    <div>
                        <LazyLoad>
                            <img src={`${process.env.PUBLIC_URL}/Images/cementImg.jpg`} alt='itemsImg' className='itemsImg img-fluid'/>
                        </LazyLoad>
                    </div>
                    <div>
                        <div className='const-cement'>
                            <h2 className='heading'>Construction Cement</h2>
                        </div>
                        <p className='details'>
                            At Altrange, we are proud to be the cornerstone of large-scale projects across India. Our unwavering commitment to delivering premium quality cement has positioned us as a trusted partner in the construction industry. With a proven track record of supplying top-grade cement to major projects nationwide, we ensure reliability, durability, and excellence in every batch. From towering infrastructures to vital developments, we stand as the reliable supplier, fortifying the foundation of India’s growth and progress.
                        </p>
                    </div>
                </div>
            </div>

            {/* Steel section */}
            <div className='container-fluid'>
                <div className='row'>
                    <div>
                        <div className='const-steel'>
                            <h2 className='heading2'>Construction Steel</h2>
                        </div>
                        <p className='details2'>
                            At Altrange, we are the catalysts behind India’s skyline, providing top-tier construction steel to projects nationwide. Our commitment to delivering high-quality steel has made us a pivotal force in the construction industry. With a steadfast dedication to reliability and excellence, we supply premium-grade steel, fortifying structures that shape the landscape of India's architectural advancements. From towering skyscrapers to essential infrastructure, we stand as the trusted supplier, ensuring strength, resilience, and enduring quality in every beam and pillar.
                        </p>
                    </div>
                    <div>
                        <LazyLoad>
                            <img src={`${process.env.PUBLIC_URL}/Images/steelConst.jpg`} alt='itemsImg' className='itemsImg2 img-fluid' />
                        </LazyLoad>
                    </div>
                </div>
            </div>

            {/* Finance section */}
            <div className='container-fluid'>
                <div className='row'>
                    <div>
                        <LazyLoad>
                            <img src={`${process.env.PUBLIC_URL}/Images/financeImg.jpg`} alt='itemsImg' className='itemsImg3 img-fluid' />
                        </LazyLoad>
                    </div>
                    <div>
                        <div className='const-finance'>
                            <h2 className='heading3'>Financing</h2>
                        </div>
                        <p className='details3'>
                            At Altrange, we empower businesses with tailored financial solutions and comprehensive project support. With a focus on fostering growth and success, we offer flexible business loans and dedicated project assistance. Our commitment lies in fueling entrepreneurial endeavors by providing strategic financial aid and hands-on guidance. From startups to established enterprises, we stand as a reliable partner, supporting ambitions and nurturing projects that drive innovation and prosperity in the business landscape.
                        </p>
                    </div>
                </div>
            </div>

            {/* Machinery section */}
            <div className='container-fluid'>
                <div className='row'>
                    <div>
                        <div className='const-machinery'>
                            <h2 className='heading4'>Construction Machinery</h2>
                        </div>
                        <p className='details4'>
                            At Altrange, we equip projects across India with cutting-edge construction machinery. As a cornerstone in the industry, our commitment to delivering top-quality machinery stands unwavering. From excavators to heavy-duty equipment, we provide a comprehensive range of machinery solutions tailored to project needs. Our dedication lies in empowering construction endeavors by offering reliable machinery, ensuring efficiency, and driving progress. Whether it's large-scale infrastructure or niche developments, we stand as the trusted supplier, enabling success and innovation in every construction endeavor.
                        </p>
                    </div>
                    <div>
                        <LazyLoad>
                            <img src={`${process.env.PUBLIC_URL}/Images/machineryImg.jpg`} alt='itemsImg' className='itemsImg4 img-fluid' />
                        </LazyLoad>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurServices;
