import React,{useState} from 'react';
import './InteractWhatsapp.css';

const InteractWhatsapp = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [apiKey,] = useState('');
  const [campaignName,] = useState('Altrange');
  const [destination,] = useState('+919592080108');
  const [userName,] = useState('');
  const [source,] = useState('');
  const [mediaUrl,] = useState('');
  const [mediaFilename,] = useState('');
  const [templateParams,] = useState([]);
  const [tags, ] = useState([]);
  const [response, setResponse] = useState({});

   const handleWhatsAppClick = async () => {
    try {
      const whatsappApiUrl = process.env.REACT_APP_WHATSAPP_API_URL;
    const data = {
      apiKey: process.env.REACT_APP_WHATSAPP_API_KEY,
      campaignName: 'Altrange',
      destination: '+919592080108',
        userName,
        source,
        media: {
          url: mediaUrl,
          filename: mediaFilename,
        },
        templateParams,
        tags,
      };
  
      const response = await fetch(whatsappApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
      setResponse(responseData);
  
      // Open the WhatsApp chat directly
      const whatsappUrl = `https://wa.me/${destination}`;
      window.open(whatsappUrl, '_blank');
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
    }
  };

  const handleSubscribeClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
    <div className="api-container">
      <div className="api-address">
         <div className='whatsapp-img'><img src={process.env.PUBLIC_URL + "/Images/whatsapp.png"}alt='whatsapp' className='whatsapp-api-container-img'  /> Altrange comes to your WhatsApp</div>
      </div>
      <p>Discover services, get Amazing cement deals related notifications and quick response <br/> within seconds on your favourite messaging app.</p>

      <div>
         <button onClick={handleSubscribeClick} className='subscribe' >Subscribe Now</button>
         

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <span className="close" onClick={handleClosePopup}>
              &times;
            </span>
            <div className="popup-header">
              <h2>Responsibility Notice</h2>
            </div>
            <div className="popup-body">
              <p>
              You are being redirected to an external/third party website. Please note that Altrange cannot be held responsible for content and privacy policies on such website.
              </p>
            </div>
            
            <div className="popup-footer">
              <button onClick={handleClosePopup} className='stay'>Stay Here</button>
              <button onClick={handleWhatsAppClick}>Continue</button>
            </div>
          </div>
          
        </div>
      
      )}
      </div>
      <div className='QR-image'><img src={`${process.env.PUBLIC_URL}/Images/QR1.png`} alt='Altrange whatsapp' />
      </div> 
      </div>
      
      </div>
   
  )
}

export default InteractWhatsapp
