import firebase from 'firebase/compat/app'; // Note the '/compat' part
import 'firebase/compat/database'; 
import 'firebase/compat/auth'; // Import Firebase Authentication
import 'firebase/compat/storage'; 

const firebaseConfig = {
  apiKey: "AIzaSyBNU6b9Ukl7aT_-6Ki3sKvRhVOe5dDUdco",
  authDomain: "altrange-55006.firebaseapp.com",
  databaseURL: "https://altrange-55006-default-rtdb.firebaseio.com",
  projectId: "altrange-55006",
  storageBucket: "altrange-55006.appspot.com",
  messagingSenderId: "684509594443",
  appId: "1:684509594443:web:e0251525a959730b6c1ece",
  measurementId: "G-Y8SEM9Z577"
};

firebase.initializeApp(firebaseConfig);
export default firebase;
