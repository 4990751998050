import React, { useEffect } from 'react';
import './AboutUs.css';

function AboutUs() {
  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
    });
  };


  useEffect(() => {
    scrollToTop();
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts

  return (
    <div className="About">
      {/* Your existing JSX content */}
      <div className="first-row">
        <div className="background-image">
          <img src="/Images/site3.jpg" alt="Background" style={{height:'500px', width:'1850px'}}/>
          <div className="overlay">
            <div className="overlay-content">
              <h1>Simply Filling Needs</h1>
              <p>At Altrange, we take pride in being a leading provider of construction materials<br></br> and financial solutions tailored
              to meet the diverse needs of our clients.Established with<br></br> a commitment to excellence, Altrange has emerged as
              a trusted partner in the construction industry.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Your existing JSX content */}
      <div className="second-row">
        <div className="content">
          <h2>Our Mission</h2>
          <p>Empowering Construction Building Futures.</p>
          <h2>Core Services</h2>
          <p>Construction Material Supply</p>
          <p>We specialize in sourcing and supplying high-quality construction materials<br></br> from reputed manufacturers to construction sites nationwide.</p>
        </div>
        <div className="image">
          <img src="/Images/constructionhero.jpg" alt="2"  />
        </div>
      </div>

      <div className="third-row">
        <div className="image">
          <img src="/Images/about_fin.jpg" alt="3" />
        </div>
        <div className="content">
          <h1>Financial Solutions</h1>
          <p>Altrange offers flexible financing options to support businesses in the construction sector.<br></br> Our financial services encompass funding for materials, machinery<br></br> and project development.</p>
        </div>
      </div>

      <div className="About-container">
        <div className="section">
          <h2>What Sets Us Apart?</h2>
          <p>Diverse Product Range: Altrange collaborates with top-tier companies to deliver a comprehensive range of<br></br>
construction materials, Presently cement & upcoming with steel,machinery.<br></br><br></br>

Reliable Delivery: We ensure timely and efficient delivery to construction sites, optimizing project timelines.<br></br><br></br>

Customized Financing: Our financial solutions are tailored to the unique needs of construction business,<br></br>
providing the necessary capital for growth and success.
</p>
        </div>

        <div className="section">
          <h2>Our Commitment to Quality:</h2>
          <p>At Altrange, quality is non-negotiable. We meticulously select our suppliers, adhering to the highest industry<br></br> standards to guarantee the excellence of the materials we provide.</p>
        </div>
      </div>

      <div className="fifth-row">
        <div className="background-image">
          <img src="/Images/about_img.jpg" alt="Background" style={{height:'400px', width:'2060px'}}/>
          <div className="overlay">
            <div className="overlay-content">
              <h1>Suggesting everyone to purchase from Altrange,<br></br>Best quality, quick response, <br></br>fast delivery reasonable rates. <br></br>Thank you!</h1>
          
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
