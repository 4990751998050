import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './career.css'; // Import CSS for styling
import JobForm from '../Forms/JobForm';

const JobPage = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const handleApply = () => {
    setShowForm(true); // Set showForm state to true on apply button click
  };

  const handleFormClose = () => {
    setShowForm(false); // Function to close the form
  };

  useEffect(() => {
    const jobsRef = firebase.database().ref('career');

    jobsRef.on('value', (snapshot) => {
      const jobData = snapshot.val();
      if (jobData) {
        const job1 = jobData.job1;
        const job2 = jobData.job2;

        const jobsArray = [
          {
            id: 'job1',
            jobName: job1.name,
            location: job1.location,
            description: job1.discription,
            salary: job1.salary,
            qualification: job1.qualification,
          },
          {
            id: 'job2',
            jobName: job2.name,
            location: job2.location,
            description: job2.discription,
            salary: job2.salary,
            qualification: job2.qualification,
          },
        ];

        setJobs(jobsArray);
      }
    });
  }, []);

  const handleJobClick = (job) => {
    setSelectedJob(job);
  };
  

  return (
    <div className="header">
      <header>
        <h1>Welcome to Our Job Opportunities!</h1>
        <p>Discover your career with us! Check out our latest job openings:</p>
      </header>
      <div className="job-page">
        <div className="left-container">
          <h2>Latest Jobs!</h2>
          <div className="job-summary-container">
            {jobs.map((job) => (
              <div key={job.id} className="job-card" onClick={() => handleJobClick(job)}>
                <h3>{job.jobName}</h3>
                <p>{job.location}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="right-block">
          <h2>{showForm ? 'Apply Now' : 'Job Details'}</h2>
          {selectedJob && !showForm && (
            <div className="job-details-container">
              <h3>{selectedJob.jobName}</h3>
              <p className="label">Location:</p>
              <p className="data">{selectedJob.location}</p>
              <p className="label">Description:</p>
              <p className="data">{selectedJob.description}</p>
              <p className="label">Salary:</p>
              <p className="data">{selectedJob.salary}</p>
              <p className="label">Qualification:</p>
              <p className="data">{selectedJob.qualification}</p>
              <button className="button-primary" onClick={handleApply}>Apply Now</button>
              {/* Add an Apply button to open the form */}
            </div>
          )}
          {showForm && (
            <div className="job-form-container">
             <JobForm/>
             <button className="button-secondary" onClick={handleFormClose}>Back</button>
            </div>
          )}
        {!selectedJob && !showForm && jobs.length === 0 && (
          <p>No vacancies yet!</p>
        )}
          {!selectedJob && !showForm && <p>Select a job to view details</p>}
        </div>
      </div>
    </div>
  );
};

export default JobPage;
