import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import { FaUser } from 'react-icons/fa'; // Import the user icon
import './Header.css';

const Header = ({ isAuthenticated }) => {

  return (
    <header className={`header-container ${isAuthenticated ? 'authenticated' : ''}`}>
      <div className="logo-container">
        <Link to="/" className="logo-link">
          <img src="/Images/altrange_logo.png" alt="Logo" className="logo" />
        </Link>
      </div>

      <div className="navbar-container">
        <Navbar />
      </div>

      <div className="login-button-container">
        {isAuthenticated ? (
          <Link to="/profile" className="user-icon">
            <FaUser/>
          </Link>
        ) : (
          <Link to="/login" className="signup-button">
            Login /Signup
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
