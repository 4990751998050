import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase'; // Replace with your Firebase configuration
import './MachineryForm.css';

const Form = () => {
  const [formData, setFormData] = useState({
    Select_Machinery: '',
    name: '', // Corrected the case of 'Color' to match the state and clear the issue of extra items
    location: '',
    start: '', // Corrected the case of 'Specs'
    returnDate: '',
    work: '',
    timestamp: '', // Corrected the case of 'Delivery'
  });

  const [successMessage, setSuccessMessage] = useState(''); // State for success message
 // Use unique identifiers for each form
const formId = 'form1'; // Unique ID for Form 1 (change for other forms)
// ...

// Fetch the submission count for the specific form
const [successfulSubmissions, setSuccessfulSubmissions] = useState(
  parseInt(localStorage.getItem(`submissionCount_${formId}`)) || 0
);

  useEffect(() => {
    const storedDate = localStorage.getItem('date');
    const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format

    console.log('Stored Date:', storedDate);
    console.log('Current Date:', currentDate);

    if (storedDate !== currentDate) {
      localStorage.setItem('date', currentDate);
      localStorage.setItem('submissionCount', '0');
      setSuccessfulSubmissions(0);
    }
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearForm = () => {
    setFormData({
      Select_Machinery: '',
      name: '', // Corrected the case of 'Color' to match the state and clear the issue of extra items
     location: '',
      start: '', // Corrected the case of 'Specs'
      returnDate: '',
      work: '', // Corrected the case of 'Delivery'
    });
  };
  const getCurrentTimestamp = () => {
    return new Date().toLocaleString(); // Get the current timestamp in human-readable format
  };

  const handleSubmit = (e) => {
    e.preventDefault();

      
    if (successfulSubmissions >= 6) {
      console.log('Successful Submissions:', successfulSubmissions);
      setSuccessMessage('Your order quota for today is finished');
      return;
    }

    const updatedCount = successfulSubmissions + 1;
setSuccessfulSubmissions(updatedCount);
localStorage.setItem(`submissionCount_${formId}`, updatedCount.toString());

    // Check if the user is authenticated
    const user = firebase.auth().currentUser;
    if (user) {
      // Get the user's UID
      const userUID = user.uid;

      formData.timestamp = getCurrentTimestamp();

      // Send the form data to Firebase with the user's UID
      const databaseRef = firebase.database().ref(`Rent Machinery/${userUID}`);
      databaseRef.push(formData);

      setSuccessMessage('Form submitted successfully!');
      clearForm(); // Clear the form after submission

      setTimeout(() => {
        setSuccessMessage(''); // Clear the success message after 1 second
      }, 2000);
    } else {
      // Handle the case where the user is not authenticated
      console.log('User is not authenticated.');
    }
  };


  return (
    <div className="machinery-form">
        {successMessage && <p className="success-message">{successMessage}</p>}
   <h2 className="heading">Rent Out Machinery</h2>

      <form onSubmit={handleSubmit}>

      <div className="form-group">
          <label htmlFor="Specs">Company Name:</label>
          <input type="text" name="name" id="Specs" value={formData.name} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="Type">Type of Machinery:</label>
          <select name="Select_Machinery" id="Type" onChange={handleChange} value={formData.Select_Machinery} required>
            <option value="">Select an option</option>
            <option value="JCB">JCB</option>
            <option value="Tractor">Tractor</option>
            <option value="Jack">Jack</option>
            <option value="Crane">Crane</option>
            <option value="Drilles">Drilles</option>
            <option value="Hammer">Hammer</option>
            <option value="Truck">Truck</option>
            {/* Add more options */}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="Specs">Site Address:</label>
          <input type="text" name="location" id="Specs" value={formData.location} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="Specs">Work Required for:</label>
          <input type="text" name="work" id="Specs" value={formData.work} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="Delievery">Start Date:</label>
          <input type="date" name="start" id="Delievery" value={formData.start} onChange={handleChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="Delievery">Return Date:</label>
          <input type="date" name="returnDate" id="Delievery" value={formData.returnDate} onChange={handleChange} required />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Form;