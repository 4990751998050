import React, { useState } from 'react';
import firebase from '../Firebase/firebase'; // Replace with your Firebase configuration
import './MachineryForm.css';

const Form = () => {
  const [formData, setFormData] = useState({
    Select_Type_of_Machinery: '',
    Color_Option: '', // Corrected the case of 'Color' to match the state and clear the issue of extra items
    Payment_Options: '',
    Tech_Specs: '', // Corrected the case of 'Specs'
    Delivery_Date: '',
    timestamp: '', // Corrected the case of 'Delivery'
  });

  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const formId = 'newmech'; // Unique ID for Form 1 (change for other forms)
  // ...
  
  // Fetch the submission count for the specific form
  const [successfulSubmissions, setSuccessfulSubmissions] = useState(
    parseInt(localStorage.getItem(`submissionCount_${formId}`)) || 0
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearForm = () => {
    setFormData({
      Select_Type_of_Machinery: '',
      Color_Option: '', // Corrected the case of 'Color' to match the state and clear the issue of extra items
      Payment_Options: '',
      Tech_Specs: '', // Corrected the case of 'Specs'
      Delivery_Date: '', // Corrected the case of 'Delivery'
    });
  };

  const getCurrentTimestamp = () => {
    return new Date().toLocaleString(); // Get the current timestamp in human-readable format
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const storedDate = localStorage.getItem('date');
  const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format

  if (storedDate !== currentDate) {
    localStorage.setItem('date', currentDate);
    localStorage.setItem('submissionCount', '0');
    setSuccessfulSubmissions(0);
  }
  const updatedCount = successfulSubmissions + 1;
  setSuccessfulSubmissions(updatedCount);
  localStorage.setItem(`submissionCount_${formId}`, updatedCount.toString());
  
    if (updatedCount > 5) {
      setSuccessMessage('Your order quota for today is finished');
      setSuccessfulSubmissions(5); // Reset to the max limit
      localStorage.setItem('submissionCount', '5'); // Reset the count in localStorage
      return;
    }

    // Check if the user is authenticated
    const user = firebase.auth().currentUser;
    if (user) {
      // Get the user's UID
      const userUID = user.uid;

      formData.timestamp = getCurrentTimestamp();

      // Send the form data to Firebase with the user's UID
      const databaseRef = firebase.database().ref(`New Machinery/${userUID}`);
      databaseRef.push(formData);

      setSuccessMessage('Form submitted successfully!');
      clearForm(); // Clear the form after submission

      setTimeout(() => {
        setSuccessMessage(''); // Clear the success message after 1 second
      }, 1000);
    } else {
      // Handle the case where the user is not authenticated
      console.log('User is not authenticated.');
    }
  };


  return (
    <div className="machinery-form">
   <h2 className="heading">Order New Machinery</h2>

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="Type">Type of Machinery:</label>
          <select name="Select_Type_of_Machinery" id="Type" onChange={handleChange} value={formData.Select_Type_of_Machinery} required>
            <option value="">Select an option</option>
            <option value="JCB">JCB</option>
            <option value="Tractor">Tractor</option>
            <option value="Jack">Jack</option>
            <option value="Crane">Crane</option>
            {/* Add more options */}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="Color">Colour Options:</label>
          <select name="Color_Option" id="Color" onChange={handleChange} value={formData.Color_Option} required>
            <option value="">Select an option</option>
            <option value="Red">Red</option>
            <option value="Yellow">Yellow</option>
            <option value="Black">Black</option>
            {/* Add more options */}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="Payment">Payment Options:</label>
          <select name="Payment_Options" id="Payment" onChange={handleChange} value={formData.Payment_Options} required>
            <option value="">Select an option</option>
            <option value="EMI">EMI</option>
            <option value="Full Payment">Full Payment</option>
            {/* Add more options */}
          </select>
        </div>
    
        <div className="form-group">
          <label htmlFor="Specs">Technical Specification:</label>
          <input type="text" name="Tech_Specs" id="Specs" value={formData.Tech_Specs} onChange={handleChange} required />
        </div>
        <div className="form-group">
          <label htmlFor="Delivery">Delivery Date:</label>
          <input type="date" name="Delivery_Date" id="Delievery" value={formData. Delivery_Date} onChange={handleChange} required />
        </div>
        <button type="submit">Submit</button>
      </form>
      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
};

export default Form;