import React from 'react';
import './Footer.css';

class ReturnPolicy extends React.Component {
  componentDidMount() {
    this.scrollToTop();
  }

  // Method to scroll to the top of the window
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling
    });
  };

  render() {
    
    return (
      <div className="terms-container">
        <h1>RETURNS, REFUNDS, REPLACEMENTS AND CANCELLATION POLICY (RP V 1.01)</h1><br/>
        <p>BY ACCESSING OR USING THE PLATFORM, YOU AGREE TO BE BOUND BY THESE RETURNS, REFUNDS, REPLACEMENTS AND CANCELLATION POLICY (“RETURN POLICY”) AND OUR OTHER AGREEMENTS INCLUDING OUR TERMS OF USE, PRIVACY POLICY AND SUCH OTHER AGREEMENTS, AS MAY BE ADOPTED BY US FROM TIME TO TIME. PLEASE READ THESE AGREEMENTS CAREFULLY BEFORE ENTERING INTO A TRANSACTION ON THE PLATFORM. THIS RETURN POLICY IS SUPPLEMENTARY TO THE TERMS OF USE AND ARE TO BE READ TOGETHER AS  DOCUMENT ALONG WITH THE OTHER AGREEMENTS ON THE PLATFORM. CAPITALISED TERMS USED IN THIS POLICY AND NOT DEFINED HEREIN SHALL HAVE THE MEANING ASCRIBED TO IT UNDER THE TERMS OF USE.</p>
        <p>This return policy is a legally binding document agreed to by the Users. This Return Policy shall be effective and binding on the buyer upon the buyer purchasing any products and/or availing related services listed on the Platform. The defined terms in the return policy will have the meaning ascribed to them in the agreements, wherever applicable.</p>
        <h2>1. Returns</h2>
        <p>
        1.1. Buyers must ensure, prior to accepting shipment of any Product, that the Product’s packaging, (as and where applicable depending on the Product), is not damaged or tampered. If the Buyer observes that the package is damaged or tampered, the Buyer may open the packaging to verify whether the Products ordered are damaged or not, and accordingly accept and/or reject delivery.<br/>
        1.2. Cancellation for Convenience. ALTRANGE   may, at its sole discretion, provide the Buyer an option to cancel an order, in full and not in part, within four (4) hours of placement, for convenience and at the sole discretion of the Buyer. Once an order is cancelled by the Buyer, ALTRANGE   shall accordingly refund the relevant amounts to the Buyer within 7 (seven) working days and pursuant to the terms of this Return Policy.
        1.3. For Cause Returns. The Buyer may in accordance with this Return Policy, raise a request for return of the Products (not being Disqualified Products) purchased on the Platform within a period of 2 (two) days from the date of delivery of the Product, due to the reasons set out below:<br/>
        a.   Product does not match the description provided on the Product page or Order page (including wrong specification, size, form or colour);<br/>
        b.   Major defects in the Product which renders it not usable in normal course such as corrosion, rusting and pitting etc.;<br/>c. Major damage during shipping or transit;
             Collectively referred to as “For Cause Returns”.<br/>
        1.4.The Buyer shall not be entitled to raise a request for return of the Products (not being Disqualified Products) purchased on the Platform for any reason other than For Cause Returns (“Other Reasons Returns”), including if You no longer need the Product after placing an Order for such Product, subject to the other terms of this Return Policy.<br/>
        1.5. The Buyer may either return the entire Order, or a part of the Order. In case of partial cancellations by the Buyer, freight charges as applicable to the Order will not be refunded, at the option of the seller and ALTRANGE.<br/>
        1.6. However, Products shall not be eligible for returns, replacement, or refunds, in the event:<br/>
          a. Request for return is not within the prescribed time period;<br/>
          b. Product is damaged because of use or Product is not in the same condition as the Buyer received it, the packs are broken and/or not in the original condition;<br/>
          c. The Buyer understands that the actual quantity serviced may vary +10% as compared to order quantity. The invoice provided at the time of delivery/self pick up, as the case may be, shall carry the exact quantity and price charged as per quantity serviced. The buyer shall be refunded part amount or make additional payment as the case may be based on negative or positive variation as compared to order quantity.
            </p>
            <p> 1.7. Some specific Products may be subject to additional terms for returns including warranties (if any), which shall be specified on the Product page on the Platform.
                     The Buyer may contact the customer service team for returns, refunds, replacement, or cancellation. The Buyer can also create a return request through the Platform, if applicable.</p>
            <p>  1.8. In order to return any Products (not being a Disqualified Product) sold through the Platform, the Buyer is required to comply with the below menthid conditions:
                   a. Notify ALTRANGE   by contacting the customer service team or through the Platform, of receipt of a damaged or defective Product within 2 (two) days from the date of delivery of the Product, along with proof of the damage or defect, such photographs, video, etc. If the Buyer is unable to do so within 2 (two) days as aforesaid, ALTRANGE   shall not be held liable to facilitate the refund or replacement of the Order;
                   b. Upon contacting the Buyer and ascertaining the reasons due to which the Buyer has initiated a returns request, ALTRANGE   shall communicate the same to the relevant seller with whom the order for placed on the Platform;
                   c. Products should be returned in their original packaging along with the original price tags, labels, barcodes, user manual, warranty card and invoices etc., where applicable;
                   d. The return packets should be strongly and adequately packaged so that there is no further damage of products during transit, where applicable;
                   e. The returned Products are subject to verification and checks by the relevant seller and ALTRANGE   in order to determine the legitimacy of the complaint / return.</p>
           <p> 1.9. Notwithstanding the above, ALTRANGE   may accept requests for returns, refund, or replacement above at its sole discretion.</p>

        <div className="terms2">
        <h2>2. Refund and Replacement Policy</h2>
         <p> 2.1. Once a request for return/replacement is received from You and communicated by ALTRANGE   to the seller, the seller shall arrange for a logistics partner / technician to visit Your location and shall resolve your complaint or mutually agree to resolution plan to your satisfaction within 3 (three) business days from the date of such intimation.</p>
         <p> 2.2. In case a complaint is not resolved or a resolution plan is not reached within 3 (three) business days of receipt of such a complaint, ALTRANGE   shall determine the resolution plan which shall be binding upon both the Buyer and the seller. ALTRANGE   shall have a right to determine that cancellation is not applicable or such other resolution plan at its sole discretion.</p>
         <p> 2.3. The Buyer shall keep ALTRANGE   informed of the status of all updates of resolution plans and any pursuant mtary settlement agreed. All settlements shall be d via the Platform.</p>
         <p> 2.4. The seller shall be solely responsible for arranging the logistics partner/technician visit, uninstallation (if required), repackaging, pick up and reverse logistics of the product from the Buyer’s location in case of returns or replacement. ALTRANGE   shall not be liable for any of the above to both the Buyer and the seller.</p>
         <p> 2.5.  In the event of false, frivolous, or baseless complaints in relation to For Cause Returns, the Buyer will not be eligible for refund or replacement. ALTRANGE   reserves its right to take appropriate actions on the Buyer in such cases, including imposing suitable mtary penalties on the Buyer.</p>
        </div>
        <div className="terms3">
        <h2>3. Refund Process</h2>
        <p>3.1. In the event that the Buyer requests for a refund, the refund will only be permissible upon ALTRANGE   authorising the refund. All refunds, pursuant to any returns or cancellations, will be credited into the relevant account/payment method through which the payment was made by the Buyer or the registered bank account of the Buyer with the Platform. The timelines for such the credit of refunds shall be notified by ALTRANGE from time to time.</p>
        <p>3.2. If the Buyer has availed third party financing from ALTRANGE’s financial partners, the refunds will be credited to such financial partners. In case, the my is refunded to the Buyer, the Buyer shall promptly thereafter repay the refund amount back to the financial partner as per the terms of the credit facility availed by the Buyer.</p>
        <p>3.3. In case the Buyer has availed any benefit under any marketing or promotions provided by ALTRANGE   in relation to the Product for which the order has been cancelled by the Buyer for any reason, the Buyer agrees and authorizes ALTRANGE   to recover such benefits from the Buyer or set-off the same from any refunds to the Buyer.</p>
        </div>

        <div className="terms3">
        <h2>4. Shipping costs</h2>
        <p> 4.1. If the Buyer returns a Product due to For Cause Returns, the Buyer will not be required to bear shipping costs in connection with such return.</p>
        <p> 4.2. Standard shipping procedures will be used to ship replacement orders, where applicable. The replacement orders will only be shipped upon receipt of the original order returned by the Buyer.</p>
        </div>
        <div className="terms4">
        <h2>5. Disputes</h2>
        <p>In the event that We determine that any Buyer has abused the Return Policy, then the Buyer may be subject to a range of actions, including claim of damages, out of pocket cost incurred to very such frivolous claims, account suspension, limiting the privileges or even termination of the User’s account. Such determination shall be at the sole discretion of ALTRANGE.</p>
        </div>
        <div className="terms4">
        <h2> 6. Applicable Law, Jurisdiction and Dispute Resolution.</h2>
        <p>6.1. If the parties are unable to resolve any such dispute or difference within a period of thirty (30) days, then any such dispute or difference arising out of, under, or in connection with this Agreement, including any question regarding its formation, existence, validity, enforceability, performance, interpretation or the breach, termination or validity thereof, shall be referred to and finally resolved by arbitration in accordance with the provisions of Arbitration and Conciliation Act, 1996, as amended from time to time.</p>
     
     <p>6.2. The arbitral tribunal shall consist of three arbitrators, appointed by each party and the said 2 (two) appointed arbitrators by the parties shall appoint the presiding arbitrator.  The venue of the arbitration shall be Chandigarh (U.T) and the language of the arbitration shall be English.</p>


    <p> 6.3. The arbitral tribunal shall have the authority to order reasonable discovery including but not limited to: the identification of witnesses, the deposition of witnesses, the production of documents, including but not limited to the exchange of any documents with a specific bearing on the interpretation of the Agreement and a party’s performance under the Agreement, and the production of any exhibits upon which a party intends to rely at the Arbitration. There shall be a hearing unless the parties agree in writing that the Arbitral tribunal may decide the case solely on the documents submitted by the part.
             The arbitral tribunal will be empowered to impose costs on any party that seeks adjournment of the proceedings or hearings, or fails to comply with any directions of the Arbitral Tribunal. After 60 (sixty) days’ written notice to the parties, the arbitral tribunal also will be empowered to dismiss the claims or strike off the defence of a party that fails to deposit the costs referred to above, pay the fees of the arbitrators, or costs as directed by the tribunal towards conduct of the arbitration proceedings.</p>
    <p> 6.4. The arbitral award shall be in writing and shall state the grounds upon which it is based. The arbitral award shall be final and binding on the parties. Unless costs are awarded in the arbitration proceeding, all expenses, costs incurred by the parties in connection with such arbitration will be borne by the party jointly except for the legal fees of the Parties. Judgment upon any award rendered by the Arbitrator may be entered by any court having jurisdiction over the parties or their assets, or an application may be made to such court for judicial recognition of the award or an order of enforcement thereof, as the case may be. This paragraph shall survive the Termination or expiry of this Agreement.</p>
    <p> 6.5. The Return Policy and applicable additional terms/policies shall be governed by and interpreted and construed in accordance with the laws of India. Subject to the above paragraph of this clause, any disputes pertaining to the Return policy shall be subject to the exclusive jurisdiction of the appropriate courts in Chandigarh (U.T.).</p></div>
      </div>
    );
  }
}

export default ReturnPolicy;
