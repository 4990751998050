import React from 'react';
import './ProcessUnderstand.css'; // Import your CSS file
import image from './Process.jpg'; // Import your image

function ProcessUnderstand() {
  return (
    <div className="process-container">
      <header>
        <h1>Process Understanding</h1>
      </header>
      <div className="image">
        <img src={image} alt="Structure" />
      </div>
    </div>
  );
}

export default ProcessUnderstand;
