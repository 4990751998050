import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase'; // Import your Firebase configuration
import './Code_unblock.css';

function CodeUnblock() {
  const [codeUnblock, setCodeUnblock] = useState({
    Existing_code: '',
    Company_name: '',
    Site_address: '',
    po: null,
    wo: null,
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCodeUnblock({ ...codeUnblock, [name]: value });
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
  
    if (file) {
      // Check file size
      if (file.size > maxSize) {
        console.error('File size exceeds the limit of 5MB.');
        // You can set an error state or display an error message to the user
        alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
        e.target.value = null; // Reset the input to allow selecting a new file
        return;
      }
  
      const storageRef = firebase.storage().ref();
  
      // Reference to the specific location where you want to store the file
      const user = firebase.auth().currentUser; // Get the currently signed-in user
      if (user) {
        const userUID = user.uid; // Get the user's UID
        const fileRef = storageRef.child(`PoandWork/${userUID}/${fileType}_${file.name}`);
  
        // Upload the file to Firebase Storage
        const uploadTask = fileRef.put(file);
  
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Progress function (optional)
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error('Error uploading file:', error);
            // You can set an error state or display an error message to the user
            alert('Error uploading file. Please try again.');
          },
          () => {
            // Handle successful uploads on complete
            console.log('File uploaded successfully');
          }
        );
      }
    }
  };
  

  const clearForm = () => {
    setCodeUnblock({
      Existing_code: '',
      Company_name: '',
      Site_address: '',
      po: null,
      wo: null,
    });
  };

  const getCurrentTimestamp = () => {
    return new Date().toLocaleString(); // Get the current timestamp in human-readable format
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if all required fields are filled
    if (codeUnblock.Existing_code && codeUnblock.Company_name && codeUnblock.Site_address) {
      // Check if the user is authenticated
      const user = firebase.auth().currentUser;
      if (user) {
        const userUID = user.uid; // Get the user's UID

        codeUnblock.timestamp = getCurrentTimestamp();

        // Get a reference to the Firebase database
        const database = firebase.database();

        // Create a new node and push the code unblock data to it under the "CodeUnblock" node with the user's UID
        const profileRef = database.ref(`Code Unblock/${userUID}`);
        profileRef.push(codeUnblock, (error) => {
          if (!error) {
            setFormSubmitted(true);
            setTimeout(() => {
              setFormSubmitted(false);
            }, 1000); // Reset the message after 1 second
          }
        });

        // Clear the form after submission
        clearForm();
      } else {
        console.log('User is not authenticated.');
      }
    }
  };


  const [downloadUrl, setDownloadUrl] = useState('');

  useEffect(() => {
    const storage = firebase.storage();
    const storageRef = storage.ref('AppGeneralData/PurchaseOrder.pdf');

    storageRef.getDownloadURL().then((url) => {
      setDownloadUrl(url);
    }).catch((error) => {
      console.error('Error getting download URL:', error);
    });
  }, []);

  const handleDownload = () => {
    if (downloadUrl) {
      window.open(downloadUrl, '_blank');
    }
  };

  return (
    <div className="codeunblock-form-container">
      <h2 className="heading">Code Unblock</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
      <button onClick={handleDownload} target="_blank">Download P.O</button>
          <label></label>
          <input
            type="text"
            name="Existing_code"
            value={codeUnblock.Existing_code}
            onChange={handleChange}
            placeholder='Current code'
            required
          />
        </div>
        <div className="form-group">
          <label></label>
          <input
            type="text"
            name="Company_name"
            value={codeUnblock.Company_name}
            onChange={handleChange}
            placeholder='Company Name'
            required
          />
        </div>
        <div className="form-group">
          <label></label>
          <input
            type="text"
            name="Site_address"
            value={codeUnblock.Site_address}
            onChange={handleChange}
            placeholder='Site Address'
            required
          />
        </div>
        <div className="form-group">
          <label>Upload P.O:</label>
          <input
            type="file"
            name="po"
            onChange={(e) => handleFileChange(e, 'po')}
          />
        </div>
        <div className="form-group">
          <label>Upload Work Order:</label>
          <input
            type="file"
            name="wo"
            onChange={(e) => handleFileChange(e, 'workorder')}
          />
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
        {formSubmitted && <p>Form submitted successfully!</p>}
      </form>
    </div>
  );
}

export default CodeUnblock;