import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './NewCodeForm.css';

function NewCodeForm() {
  const [formData, setFormData] = useState({
    newcode: '',
    name: '',
    address: '',
    person: '',
    number: '',
    email: '',
    pan: '',
    gstin: '',
    tan: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

  const isValidPAN = (pan) => {
    return panPattern.test(pan);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
  
    if (file) {
      // Check file size
      if (file.size > maxSize) {
        console.error('File size exceeds the limit of 5MB.');
        // You can set an error state or display an error message to the user
        alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
        e.target.value = null; // Reset the input to allow selecting a new file
        return;
      }
  
      const storageRef = firebase.storage().ref();
      const user = firebase.auth().currentUser;
  
      if (user) {
        const userUID = user.uid;
        const fileRef = storageRef.child(`OpenNewCode/${userUID}/${file.name}`);
  
        // Upload the file to Firebase Storage
        const uploadTask = fileRef.put(file);
  
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Progress function (optional)
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            // Handle unsuccessful uploads
            console.error('Error uploading file:', error);
            // You can set an error state or display an error message to the user
            alert('Error uploading file. Please try again.');
          },
          () => {
            // Handle successful uploads on complete
            console.log('File uploaded successfully');
            // Store the image URL in your database or use it as needed
            // You can set it in formData or save it separately
          }
        );
      }
    }
  };
  

  const getCurrentTimestamp = () => {
    return new Date().toLocaleString(); // Get the current timestamp in human-readable format
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValidPAN(formData.pan)) {
      // PAN number is invalid
      alert("Invalid PAN number. Please enter a valid PAN.");
      return;
    }

    const user = firebase.auth().currentUser;

    if (user) {
      const userUID = user.uid;
      const databaseRef = firebase.database().ref(`New Code/${userUID}`);
      const dataToSave = {
        newcode: formData.newcode,
        name: formData.name,
        address: formData.address,
        person: formData.person,
        number: formData.number,
        email: formData.email,
        pan: formData.pan,
        gstin: formData.gstin,
        tan: formData.tan,
        timestamp: getCurrentTimestamp(),
      };

      databaseRef.push(dataToSave, (error) => {
        if (error) {
          console.error('Error adding data: ', error);
        } else {
          console.log('Data added successfully!');
          setFormData({
            newcode: '',
            name: '',
            address: '',
            person: '',
            number: '',
            email: '',
            pan: '',
            gstin: '',
            tan: '',
          });
          setFormSubmitted(true);

          setTimeout(() => {
            setFormSubmitted(false);
          }, 1000);
        }
      });
    } else {
      console.log('User is not authenticated.');
    }
  };

  const [selectedPO, setSelectedPO] = useState('');
  const [selectedTCS, setSelectedTCS] = useState('');

  const handleDownload = (selectedOption, fileType) => {
    let storagePath = '';

    if (!selectedOption) {
      // If selectedOption is null or empty, return without performing any action
      return;
    }
  
    if (selectedOption === 'Ultratech') {
      if (fileType === 'PO') {
        storagePath = 'AppGeneralData/PurchaseOrder.pdf';
      } else if (fileType === 'TCS') {
        storagePath = 'AppGeneralData/Declarations.pdf';
      }
    } else if (selectedOption === 'Shree') {
      if (fileType === 'PO') {
        storagePath = 'AppData/PurchaseOrder.pdf';
      } else if (fileType === 'TCS') {
        storagePath = 'AppData/Declarations.pdf';
      }
    } 

    const storageRef = firebase.storage().ref(storagePath);
  
    storageRef.getDownloadURL().then((url) => {
      if (url) {
        window.open(url, '_blank');
      }
    }).catch((error) => {
      console.error('Error getting download URL:', error);
    });
  };
  

  return (
    <div className="newcode-container">
    <h1>New Code Form</h1>
    <div className="download-container">
      <div>
        <label htmlFor="downloadPO">Purchase Order:</label>
        <select
          id="downloadPO"
          value={selectedPO}
          onChange={(e) => setSelectedPO(e.target.value)}
        >
          <option disabled value=""> Select</option>
          <option value="Ultratech">Ultratech Cement</option>
          <option value="Shree">Shree Cement</option>
        </select>
        <button onClick={() => handleDownload(selectedPO, 'PO')}>Download</button>
      </div>
      <div>
        <label htmlFor="downloadTCS">TCS/TDS Declarations:</label>
        <select
          id="downloadTCS"
          value={selectedTCS}
          onChange={(e) => setSelectedTCS(e.target.value)}
        >
          <option disabled value=""> Select</option>
          <option value="Ultratech">Ultratech Cement</option>
          <option value="Shree">Shree Cement</option>
        </select>
        <button onClick={() => handleDownload(selectedTCS, 'TCS')}>Download</button>
      </div>
    </div>

      <form onSubmit={handleSubmit}>

      <div className="form-group-container">

        <div className="form-group">
          <label htmlFor="newcode">New Code Company:</label>
          <select
            name="newcode"
            value={formData.newcode}
            onChange={handleChange}
            required
          >
            <option value="">Select Company</option>
            <option value="Ultratech">Ultratech Cement</option>
            <option value="Shree">Shree Cement</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="name">Firm Name:</label>
          <input
            className="input-field"
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter Name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="address">Address:</label>
          <input
            className="input-field"
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="Enter Address"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="person">Contact Person Details:</label>
          <input
            className="input-field"
            type="text"
            id="person"
            name="person"
            value={formData.person}
            onChange={handleChange}
            placeholder="Contact person name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="number">Contact Number:</label>
          <input
            className="input-field"
            type="text"
            id="number"
            name="number"
            value={formData.number}
            onChange={handleChange}
            placeholder="Contact number"
            required
          />
        </div>
        </div>
        <div className="form-group-container">

        <div className="form-group">
          <label htmlFor="email">Person Email:</label>
          <input
            className="input-field"
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="name123@email.com"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="pan">PAN number:</label>
          <input
            className="input-field"
            type="text"
            id="pan"
            name="pan"
            value={formData.pan}
            onChange={handleChange}
            placeholder="ABCDE1234F"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="gstin">GSTIN number:</label>
          <input
            className="input-field"
            type="text"
            id="gstin"
            name="gstin"
            value={formData.gstin}
            onChange={handleChange}
            placeholder="29AAAAA1234C1ZF"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="tan">TAN number:</label>
          <input
            className="input-field"
            type="text"
            id="tan"
            name="tan"
            value={formData.tan}
            onChange={handleChange}
            placeholder="ABCD12345E"
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">TCS/TDS Declaration:</label>
          <input
            className="input-field"
            type="file"
            id="image"
            name="image"
            onChange={handleImageUpload}
          />
        </div>
        </div>
        <div className="form-group-container">

        <div className="form-group">
          <label htmlFor="image">Work Order:</label>
          <input
            className="input-field"
            type="file"
            id="image"
            name="image"
            onChange={handleImageUpload}
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Pan Card:</label>
          <input
            className="input-field"
            type="file"
            id="image"
            name="image"
            onChange={handleImageUpload}
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Purchase Order:</label>
          <input
            className="input-field"
            type="file"
            id="image"
            name="image"
            onChange={handleImageUpload}
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">GSTIN Certificate:</label>
          <input
            className="input-field"
            type="file"
            id="image"
            name="image"
            onChange={handleImageUpload}
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Partnership Deed:</label>
          <input
            className="input-field"
            type="file"
            id="image"
            name="image"
            onChange={handleImageUpload}
          />
        </div>
        </div>
        <button type="submit" className="submit-button">
          Submit
        </button>
        {formSubmitted && <p>Form submitted successfully!</p>}
      </form>
    </div>
  );
}

export default NewCodeForm;