import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getDatabase, ref, get, set, child, update } from 'firebase/database';

import './StockCheck.css'; // Make sure to create this CSS file

const DEFAULT_ID = 'eWemF8yGxtcFBoRDPbRzh3tGwrE3';

const StockCheck = () => {
  const [stockData, setStockData] = useState([]);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getDatabase();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user.uid);
        createUserNodeIfNeeded(user.uid);
        syncStockDataToAllUsers();
      } else {
        console.log('No user is signed in.');
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchUserData = async (uid) => {
    try {
      const userRef = ref(db, `StockAvailability/${uid}`);
      const entries = ['1', '2', '3', '4'];
      const dataPromises = entries.map((entryNumber) =>
        get(child(userRef, `entry${entryNumber}`)).then((snapshot) => ({
          entryNumber,
          data: snapshot.exists() ? snapshot.val() : null,
        })),
      );

      const dataResults = await Promise.all(dataPromises);
      setStockData(dataResults);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const createUserNodeIfNeeded = async (uid) => {
    try {
      const userRef = ref(db, `StockAvailability/${uid}`);
      const snapshot = await get(userRef);
      if (!snapshot.exists()) {
        await set(userRef, createDefaultStockData());
      }
    } catch (error) {
      console.error('Error creating user node:', error);
    }
  };

  const createDefaultStockData = () => {
    return {
      entry1: { depo: '', grade: '', bags: '' },
      entry2: { depo: '', grade: '', bags: '' },
      entry3: { depo: '', grade: '', bags: '' },
      entry4: { depo: '', grade: '', bags: '' },
    };
  };

  const syncStockDataToAllUsers = async () => {
    try {
      const defaultRef = ref(db, `StockAvailability/${DEFAULT_ID}`);
      const snapshot = await get(defaultRef);
      if (snapshot.exists()) {
        const stockData = snapshot.val();
        await updateAllUserStockData(stockData);
      } else {
        console.log('Data for default_id does not exist');
      }
    } catch (error) {
      console.error('Error syncing stock data:', error);
    }
  };

  const updateAllUserStockData = async (stockData) => {
    try {
      const usersRef = ref(db, 'StockAvailability');
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const updates = {};
        snapshot.forEach((userSnapshot) => {
          const userId = userSnapshot.key;
          if (userId !== DEFAULT_ID) {
            updates[`StockAvailability/${userId}`] = stockData;
          }
        });
        await update(ref(db), updates);
      }
    } catch (error) {
      console.error('Error updating all user stock data:', error);
    }
  };

  const showInfoDialog = () => {
    alert('In Stock: \n Indicates that the items are in stock and ready for prompt fulfillment.\n Out of Stock:\n  Indicates that the stock is not available and cannot be fulfilled until it is restocked.\n In Transit:\n Indicates that the stock is currently being transported and will be available soon.\n Note:\n  Stock status may vary over time. Kindly contact our support team for the most accurate information.');
  };

  const getStockStatusText = (bags) => {
    if (!bags || bags === '00') {
      return { text: 'Out of Stock', color: 'var(--outOfStockColor)' }; // Out of stock color
    } else if (bags === 'available soon') {
      return { text: 'In Transit', color: 'orange' }; // In transit color
    }
    return { text: 'In Stock', color: 'var(--inStockColor)' }; // In stock color
  };

  return (
    <div className="container">
      <div className="scrollable-container">
        <div>
          <div className='existingsite-data'>
            <span className="heading">Stock Information</span>
          </div>
          <img 
            src={`${process.env.PUBLIC_URL}/Images/info-icon.png`} 
            onClick={showInfoDialog} 
            className='stock-info-icon' 
            alt="info icon"
          />
        </div>
        {stockData.length > 0 ? (
          stockData.map(({ entryNumber, data }, index) => (
            <div className="linear-layout" key={entryNumber}>
              <div className="horizontal-layout">
                <div className="label">Depot / Plant - </div>
                <div className="value">{data?.depo || ''}</div>
              </div>
              <div className="divider"></div>
              <div className="horizontal-layout">
                <div className="label">Grade - </div>
                <div className="value">{data?.grade || ''}</div>
              </div>
              <div className="divider"></div>
              <div className="horizontal-layout">
                <div className="label">Stock Status - </div>
                <div
                  className="value"
                  style={{ color: getStockStatusText(data?.bags).color }}
                >
                  {getStockStatusText(data?.bags).text}
                </div>
              </div> 
            </div>
          ))
        ) : (
          <p>No stock data available.</p>
        )}
      </div>
    </div>
  );
};

export default StockCheck;
