import React, { useState } from 'react';
import './ChatWeb.css'

const ChatWeb = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [apiKey,] = useState('');
  const [campaignName,] = useState('Altrange');
  const [destination,] = useState('+919592080108');
  const [userName,] = useState('');
  const [source,] = useState('');
  const [mediaUrl,] = useState('');
  const [mediaFilename,] = useState('');
  const [templateParams,] = useState([]);
  const [tags, ] = useState([]);
  const [response, setResponse] = useState({});

   const handleWhatsAppClick = async () => {
    try {
      const whatsappApiUrl = process.env.REACT_APP_WHATSAPP_API_URL;
    const data = {
      apiKey: process.env.REACT_APP_WHATSAPP_API_KEY,
      campaignName: 'Altrange',
      destination: '+919592080108',
        userName,
        source,
        media: {
          url: mediaUrl,
          filename: mediaFilename,
        },
        templateParams,
        tags,
      };
  
      const response = await fetch(whatsappApiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const responseData = await response.json();
      setResponse(responseData);
  
      // Open the WhatsApp chat directly
      const whatsappUrl = `https://wa.me/${destination}`;
      window.open(whatsappUrl, '_blank');
    } catch (error) {
      console.error('Error sending WhatsApp message:', error);
    }
  };

  return (
    <div className='whatsapp-button-wrapper'>
   
   <img
  src={process.env.PUBLIC_URL + "/Images/whatsapp.png"}
  alt="Chat"
  className="whatsapp-button"
  onClick={handleWhatsAppClick}
/>

    </div>
  );
};

export default ChatWeb;