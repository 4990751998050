import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './vendorForm.css';

function VendorForm() {
  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    email: '',
    number: '',
    address: '',
  });

  const [productInfo, setProductInfo] = useState({
    Pname: '',
    Pdiscript: '',
    Psepcs: '',
    priceavail: '',
    area: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleCompanyInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyInfo({ ...companyInfo, [name]: value });
  };

  const handleProductInputChange = (e) => {
    const { name, value } = e.target;
    setProductInfo({ ...productInfo, [name]: value });
  };

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file) {
      if (file.size > maxSize) {
        alert('File size exceeds the limit of 5MB. Please choose a smaller file.');
        e.target.value = null;
        return;
      }

      const storageRef = firebase.storage().ref();

      if (user) {
        const userUID = user.uid;
        const fileRef = storageRef.child(`Finance/${userUID}/${fileType}_${file.name}`);

        const uploadTask = fileRef.put(file);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload is ${progress}% done`);
          },
          (error) => {
            console.error('Error uploading file:', error);
            alert('Error uploading file. Please try again.');
          },
          () => {
            console.log('File uploaded successfully');
          }
        );
      } else {
        setError('User is not authenticated. Please log in and try again.');
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (user) {
      const userUID = user.uid;

      try {
        const companyRef = firebase.database().ref(`Company/${userUID}`);
        await companyRef.push(companyInfo);
        console.log('Company info submitted:', companyInfo);

        const productRef = firebase.database().ref(`Product/${userUID}`);
        await productRef.push(productInfo);
        console.log('Product info submitted:', productInfo);

        setSuccessMessage('Vendor application submitted successfully');
        setTimeout(() => setSuccessMessage(''), 3000);
      } catch (error) {
        console.error('Error submitting vendor application:', error);
        setError('Error submitting vendor application. Please try again.');
      }
    } else {
      setError('User is not authenticated. Please log in and try again.');
    }

    setCompanyInfo({
      name: '',
      email: '',
      number: '',
      address: '',
    });

    setProductInfo({
      Pname: '',
      Pdiscript: '',
      Psepcs: '',
      priceavail: '',
      area: '',
    });
  };

  return (
    <div className="loan-form-container">
      <h1 className="title">Vendor Application Form</h1>
      <form className="loan-form" onSubmit={handleSubmit}>
        <div className="form-category">
          <h2>Company Info</h2>
          <label className="loan-label">Company Name:</label>
          <input
            className="loan-input"
            type="text"
            name="name"
            value={companyInfo.name}
            onChange={handleCompanyInputChange}
            required
          />
          <label className="loan-label">Email:</label>
          <input
            className="loan-input"
            type="email"
            name="email"
            value={companyInfo.email}
            onChange={handleCompanyInputChange}
            required
          />
          <label className="loan-label">Contact number:</label>
          <input
            className="loan-input"
            type="tel"
            name="number"
            value={companyInfo.number}
            onChange={handleCompanyInputChange}
            required
          />
          <label className="loan-label">Address:</label>
          <input
            className="loan-input"
            type="text"
            name="address"
            placeholder="Enter Address"
            value={companyInfo.address}
            onChange={handleCompanyInputChange}
            required
          />
        </div>
        <div className="form-category">
          <h2>Product Info</h2>
          <label className="loan-label">Product Name:</label>
          <input
            className="loan-input"
            type="text"
            name="Pname"
            placeholder="Product name"
            value={productInfo.Pname}
            onChange={handleProductInputChange}
            required
          />
          <label className="loan-label">Product Description:</label>
          <input
            className="loan-input"
            type="text"
            name="Pdiscript"
            placeholder="Describe in a few words."
            value={productInfo.Pdiscript}
            onChange={handleProductInputChange}
          />
          <label className="loan-label">Product Specification:</label>
          <input
            className="loan-input"
            type="text"
            name="Psepcs"
            placeholder="Key specs"
            value={productInfo.Psepcs}
            onChange={handleProductInputChange}
          />
          <label className="loan-label">Product Pricing & Availability:</label>
          <input
            className="loan-input"
            type="text"
            name="priceavail"
            placeholder="Rates and Availability"
            value={productInfo.priceavail}
            onChange={handleProductInputChange}
          />
          <label className="loan-label">Areas under your service:</label>
          <input
            className="loan-input"
            type="text"
            name="area"
            placeholder="service areas"
            value={productInfo.area}
            onChange={handleProductInputChange}
          />
        </div>
        <div className="form-category">
          <h2>File Upload</h2>
          <label className="loan-label">Product Visuals (PDF file):</label>
          <input
            className="loan-input"
            type="file"
            onChange={(e) => handleFileChange(e, 'ProductVisuals')}
            required
          />
        </div>
        <button className="loan-button" type="submit">
          Submit
        </button>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
}

export default VendorForm;

