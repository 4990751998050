import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './Notification.css'; // Add your CSS file for styling

const Notifications = () => {
  const [notification, setNotification] = useState('');
  const [loading, setLoading] = useState(true);

  const user = firebase.auth().currentUser;

  useEffect(() => {
    if (user) {
      const noteRef = firebase.database().ref('profile/' + user.uid + '/note');
      noteRef.on('value', (snapshot) => {
        const data = snapshot.val() || '';
        setNotification(data);
        setLoading(false);
      });
    }
  }, [user]);

  return (
    <div className="notifications-container">
      <div className="notifications-frame">
        {loading ? (
          <p></p>
        ) : notification ? (
          <div className="notification-card">
            <p className="notification-text">{notification}</p>
            </div>
     
        ) : (
          <p>No notifications available.</p>
        )}
      </div>
    </div>
  );
};

export default Notifications;
