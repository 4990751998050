import React, { useState, useEffect } from 'react';
import './Machinery.css';
import NewMech from '../Forms/MachineryForm';
import UsedMech from '../Forms/UsedMech';
import firebase from '../Firebase/firebase';


const Machinery = () => {
 // Function to scroll to the top of the page
 const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // Smooth scrolling animation
  });
};


useEffect(() => {
  scrollToTop();
}, []); // Empty dependency array ensures this effect runs only once, when the component mounts


  const [showStatusCard, setShowStatusCard] = useState(false);
  const [balanceData, setBalanceData] = useState({});
  const [selectedForm, setSelectedForm] = useState('machinery');

  const user = firebase.auth().currentUser;

  useEffect(() => {
    const balanceRef = firebase.database().ref('profile/' + user.uid + '/m_status');

    const fetchBalanceData = () => {
      balanceRef.on('value', (balanceSnapshot) => {
        const balanceData = balanceSnapshot.val() || {};
        setBalanceData(balanceData);
      });
    };

    fetchBalanceData();

    return () => {
      balanceRef.off('value');
    };
  }, [user.uid]);

  const handleOrderStatusClick = () => {
    setShowStatusCard(true);
    setSelectedForm('');
  };

  const handleFormClick = (formType) => {
    setShowStatusCard(false);
    setSelectedForm(formType);
  };

  return (
    <div className="machinery-page">
      <div className="machinery-block">
        <div className="header-block">
          <h1>Construction Machinery</h1>
          <div className="mech-nav-bar">
            <ul>
              <li onClick={() => handleFormClick('machinery')}>Buy New</li>
              <li onClick={() => handleFormClick('used')}>On Rent</li>
              <li onClick={handleOrderStatusClick}>Order status</li>
            </ul>
          </div>
          <h3>
            You can order new, used, or rent also.<br />
            Submit the form as per your requirements.<br />
            We will call you soon with the best options we have.
          </h3>
        </div>
        <div className="form-block" id="form-container">
          {showStatusCard && (
            <div className="status-card">
              <h2>Order Status</h2>
              <div className="status-data">
                <pre>{JSON.stringify(balanceData, null, 2)}</pre>
              </div>
            </div>
          )}
          {!showStatusCard && selectedForm === 'machinery' && <NewMech />}
          {!showStatusCard && selectedForm === 'used' && <UsedMech />}
        </div>
      </div>
    </div>
  );
};

export default Machinery;
