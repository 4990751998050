import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './bankdetail.css';

const AccountDetails = () => {
  const [selectedAccount, setSelectedAccount] = useState('');
  const [accountData, setAccountData] = useState({});
  const [user, setUser] = useState(null);

  const labels = {
    b_name: 'Beneficiary Name',
    account: 'Account Number',
    branch: 'Bank & Branch Name',
    ifsc: 'IFSC Code',
    site: 'Site Location',
    // Add more labels if needed for your data fields
  };

  const labelOrder = ['site', 'b_name', 'account', 'ifsc', 'branch']; 
  
  useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    setUser(currentUser);
  }, []);

  useEffect(() => {
    if (user && selectedAccount) {
      const accountRef = firebase.database().ref(`AccountDetails/${user.uid}/${selectedAccount}`);
      accountRef.on('value', (snapshot) => {
        const data = snapshot.val() || {};
        setAccountData(data);
      });
    }
  }, [user, selectedAccount]);

  const handleAccountSelection = (accountType) => {
    setSelectedAccount(accountType);
  };

 return (
  <div>
    <h1 className="page-heading">Company Account Details</h1>
    <div className="account-options">
    <button onClick={() => handleAccountSelection('Ultratech')}>Ultratech cement</button>
        <button onClick={() => handleAccountSelection('Shree')}>Shree Cement</button>
        <button onClick={() => handleAccountSelection('Acc')}>Acc Cement</button>
    </div>
    <div className="account-details">
      {Object.keys(accountData).length > 0 ? (
        <div className="account-info">
          <h2>{selectedAccount} Details</h2>
          <div className="account-info-vertical">
            {labelOrder.map((labelKey) => (
              // Render each label and data
              <div key={labelKey} className="account-info-item">
                {accountData[labelKey] ? (
                  // If data exists, show label and data
                  <>
                    <strong>{labels[labelKey]}:</strong> {accountData[labelKey]}
                  </>
                ) : null /* If data is empty or null, don't render anything */}
              </div>
            ))}
          </div>
          {!(Object.keys(accountData).length && Object.values(accountData).some(data => data !== '')) && (
            <p>No data is available for now</p>
          )}
        </div>
      ) : (
        <p>{!selectedAccount ? 'Select a Company' : `No data available for ${selectedAccount}`}</p>
      )}
    </div>
  </div>
);
};

export default AccountDetails;
