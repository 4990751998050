import React, { useState, useEffect } from 'react';
import firebase from '../Firebase/firebase';
import './Profile.css';

const Profile = () => {
  const [userData, setUserData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [userList, setUserList] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [newData, setNewData] = useState({
    name: '',
    email: '',
    company_name: '',
    address: '',
    state: '',
    brand_supply: '',
    commodity_serviced: '',
    bank_details: '',
    active: '',
    order_expected: '',
    payment_expected: ''
  });

  const user = firebase.auth().currentUser;

  useEffect(() => {
    if (user) {
      const userRef = firebase.database().ref('profile/' + user.uid);
      userRef.on('value', (snapshot) => {
        const data = snapshot.val();
        setUserData(data);
        setNewData({
          name: data.name || '',
          email: data.email || '',
          company_name: data.company_name || '',
          address: data.address || '',
          state: data.state || '',
          brand_supply: data.brand_supply || '',
          commodity_serviced: data.commodity_serviced || '',
          bank_details: data.bank_details || '',
          active: data.active || '',
          order_expected: data.order_expected || '',
          payment_expected: data.payment_expected || ''
        });
      });
    }
  }, [user]);

  useEffect(() => {
    const userRef = firebase.database().ref('profile');
    userRef.on('value', (snapshot) => {
      const data = snapshot.val();
      const users = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      setUserList(users);
    });
  }, []);

  const handleEditProfile = () => {
    setIsEditing(true);
  };

  const handleSaveProfile = () => {
    const userRef = firebase.database().ref('profile/' + user.uid);
    userRef.update(newData);
    setIsEditing(false);
  };

  const handleDeleteAccount = () => {
    const confirmDelete = window.confirm('Are you sure you want to delete your account? This action cannot be undone.');
    if (confirmDelete) {
      const userRef = firebase.database().ref('profile/' + user.uid);
      userRef.remove();
      firebase.auth().signOut();
    }
  };

  const handleLogout = () => {
    firebase.auth().signOut();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleUserSelect = (id) => {
    setSelectedUserId(id);
    const selectedUser = userList.find(user => user.id === id);
    if (selectedUser) {
      setUserData(selectedUser);
      setNewData({
        name: selectedUser.name || '',
        email: selectedUser.email || '',
        company_name: selectedUser.company_name || '',
        address: selectedUser.address || '',
        state: selectedUser.state || '',
        brand_supply: selectedUser.brand_supply || '',
        commodity_serviced: selectedUser.commodity_serviced || '',
        bank_details: selectedUser.bank_details || '',
        active: selectedUser.active || '',
        order_expected: selectedUser.order_expected || '',
        payment_expected: selectedUser.payment_expected || ''
      });
    }
  };

  const filteredUsers = userList.filter(user => user.name.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="profile-container">
      <input
        type="text"
        placeholder="Search client name..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-input"
      />
      <ul className="user-list">
        {filteredUsers.map(user => (
          <li key={user.id} onClick={() => handleUserSelect(user.id)}>
            {user.name}
          </li>
        ))}
      </ul>
      {selectedUserId && (
        <div className="profile-frame">
          <div className="profile-info">
            {isEditing ? (
              <>
                <div className="input-label">
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Your name"
                    value={newData.name}
                    onChange={(e) => setNewData({ ...newData, name: e.target.value })}
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="abc123@mail.com"
                    value={newData.email}
                    onChange={(e) => setNewData({ ...newData, email: e.target.value })}
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="companyName">Company Name:</label>
                  <input
                    type="text"
                    id="companyName"
                    placeholder="Company Name"
                    value={newData.company_name}
                    onChange={(e) => setNewData({ ...newData, company_name: e.target.value })}
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="address">Address:</label>
                  <input
                    type="text"
                    id="address"
                    placeholder="Address"
                    value={newData.address}
                    onChange={(e) => setNewData({ ...newData, address: e.target.value })}
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="state">State:</label>
                  <input
                    type="text"
                    id="state"
                    placeholder="State"
                    value={newData.state}
                    onChange={(e) => setNewData({ ...newData, state: e.target.value })}
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="brand_supply">Brand Supply:</label>
                  <input
                    type="text"
                    id="brand_supply"
                    placeholder="Brand Supply"
                    value={newData.brand_supply}
                    onChange={(e) => setNewData({ ...newData, brand_supply: e.target.value })}
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="commodity_serviced">Commodity Serviced:</label>
                  <input
                    type="text"
                    id="commodity_serviced"
                    placeholder="Commodity Serviced"
                    value={newData.commodity_serviced}
                    onChange={(e) => setNewData({ ...newData, commodity_serviced: e.target.value })}
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="bank_details">Bank Details:</label>
                  <input
                    type="text"
                    id="bank_details"
                    placeholder="Bank Details"
                    value={newData.bank_details}
                    onChange={(e) => setNewData({ ...newData, bank_details: e.target.value })}
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="active">Active/Blocked:</label>
                  <input
                    type="text"
                    id="active"
                    placeholder="Active/Blocked"
                    value={newData.active}
                    onChange={(e) => setNewData({ ...newData, active: e.target.value })}
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="order_expected">Order Expected:</label>
                  <input
                    type="date"
                    id="order_expected"
                    placeholder="Order Expected"
                    value={newData.order_expected}
                    onChange={(e) => setNewData({ ...newData, order_expected: e.target.value })}
                  />
                </div>
                <div className="input-label">
                  <label htmlFor="payment_expected">Payment Expected:</label>
                  <input
                    type="date"
                    id="payment_expected"
                    placeholder="Payment Expected"
                    value={newData.payment_expected}
                    onChange={(e) => setNewData({ ...newData, payment_expected: e.target.value })}
                  />
                </div>
                <button onClick={handleSaveProfile} className="save">Save</button>
                <button onClick={handleDeleteAccount} className="delete">Delete Account</button>
              </>
            ) : (
              <div className="profile-details">
                <p><strong>Name:</strong> <span>{userData.name}</span></p>
                <p><strong>Email:</strong> <span>{userData.email}</span></p>
                <p><strong>Company Name:</strong> <span>{userData.company_name}</span></p>
                <p><strong>Address:</strong> <span>{userData.address}</span></p>
                <p><strong>State:</strong> <span>{userData.state}</span></p>
                <p><strong>Brand Supply:</strong> <span>{userData.brand_supply}</span></p>
                <p><strong>Commodity Serviced:</strong> <span>{userData.commodity_serviced}</span></p>
                <p><strong>Bank Details:</strong> <span>{userData.bank_details}</span></p>
                <p><strong>Active/Blocked:</strong> <span>{userData.active}</span></p>
                <p><strong>Order Expected:</strong> <span>{userData.order_expected}</span></p>
                <p><strong>Payment Expected:</strong> <span>{userData.payment_expected}</span></p>
                <button onClick={handleLogout} className="logout">Logout</button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
