import React, { useState, useEffect } from 'react';
import './landingPage.css';
import { useNavigate, Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

function LandingPage() {
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    let requestId;
  const animate = () => {
    setShowOverlay(true);
    requestId = requestAnimationFrame(animate);
  };
  animate();
  return () => {
    cancelAnimationFrame(requestId);
  };
  }, []);

  return (
    <div className="landing-page">
      <div className="header-1">
        <LazyLoad>
          <img src="/Images/homebackground2.jpg" alt="Construction GIF" className="bg-image"  />
          </LazyLoad>
          {showOverlay && (
        <div className="overlay">
          <div className="header-2">
            <h1>Welcome to Altrange</h1>
            <p>Get all your Construction needs at one place</p>
            <div className="button-container">
              <button className="entry_button" onClick={() => navigate('/Home')}>Start Placing Orders</button>
              <button className="contact_button" onClick={() => navigate('/contactus')}>Talk with Sales</button>
            </div>
          </div>
        </div>
        )}
      </div>
      <div className="tiles-container">
        <div className="tiles">
          <div className="tile">
            <h2>Our Services</h2>
            <p>Explore our range of services</p>
            <LazyLoad>
            <Link to="/raw-materials"><img className="services-icon" src="/Images/cement-steel.png" alt="Service 2" /></Link>
            <Link to="/finance"><img className="services-icon" src="/Images/financeimg.png" alt="Service 3" /></Link>
            <Link to="/machinerypage"><img className="services-icon" src="/Images/construction.png" alt="Service 1" /></Link>
            </LazyLoad>
          </div>
          <div className="tile">
            <h2>Steps to Place Order</h2>
            <p>Learn how to place an order with us</p>
            <LazyLoad>
            <img className="order-icon" src="/Images/complete-profile.png" alt="Step 1" />
            <img className="order-icon" src="/Images/order_icon.png" alt="Step 2" />
            <img className="order-icon" src="/Images/heading.png" alt="Step 3" />
            <img className="order-icon" src="/Images/submit.png" alt="Step 4" />
            </LazyLoad>
          </div>
          <div className="vendor-steps-container">
            <h2>Benefits with us</h2>
            <div className="steps">
              <div className="step">
              <LazyLoad>
                <img src="/Images/price.png" alt="price" />
                <p>Cost-Effective Solutions: Discover cost-effective solutions for your construction needs.</p>
                </LazyLoad>
              </div>
              <div className="step">
              <LazyLoad>
                <img src="/Images/details.png" alt="easy" />
                <p>Effortless Ordering: Streamlined ordering process via our website and apps.</p>
                </LazyLoad>
              </div>
              <div className="step">
              <LazyLoad>
                <img src="/Images/contact.png" alt="workflow" />
                <p>Simple Workflow: Seamless and hassle-free process from order to delivery.</p>
                </LazyLoad>
              </div>
              <div className="step">
              <LazyLoad>
                <img src="/Images/caller.jpg" alt="caller" />
                <p>Dedicated Support: 24/7 support team ready to assist with any inquiries.</p>
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default LandingPage;
